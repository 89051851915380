import React, { Component } from 'react';

class Home extends Component {

  componentDidMount() {
  }

  render() {
    return (
      <div className="container-fluid">
          <img className="animated fadeIn" style={{width: '100%'}} src="./images/bg_hero.jpg" alt="This is the homepage"/>
      </div>
    )
  }
}

export default Home;
