import React, { Component } from 'react';
import axios from 'axios';
// eslint-disable-next-line
import { BrowserRouter as Router, Route, Link, Switch, Redirect } from 'react-router-dom';

class PassReset extends Component {

  constructor(props) {
    super(props);
    this.state = {
      resetToken: undefined,
      showPassResetRequestInterface: false,
      showPasswordResetInterface: false,
      email: undefined,
      password: undefined,
      confirmPassword: undefined
    };
  };

  componentWillReceiveProps({isAuthorized}) {
    // if current user is already logged in, redirect back to homepage
    if (isAuthorized) {
      window.location.assign('/');
    };

  }

  componentDidMount = () => {

    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    let token = urlParams.get('token')

    if (token) {
      this.setState({
        resetToken: token
      })
      axios.post('/api/password-reset/verify/', { token: token})
      .then((result) => {
        if (result.status === 200) {
          this.setState({
            showPasswordResetInterface: true
          })
          return
        }
      }, (error) => {
        this.setState({
          showPassResetRequestInterface: true
        })
      })
    } else {
      this.setState({
        showPassResetRequestInterface: true
      })
    }
  }

  onChange = (event) => {
    event.preventDefault();
    this.setState({[event.target.name]: event.target.value});
    try {
      document.getElementById('confirmPasswordDidFailValidation').innerHTML = ''
    } catch (error) {}
  };

  sendPassResetRequestEmail = (event) => {
    event.preventDefault();

    let emailField = document.getElementById('emailField');

    if (emailField.value === '') {
      emailField.className += ' animated bounceIn'
      return;
    };

    document.getElementById('passResetButton').setAttribute('disabled', 'true');
    document.getElementById('passResetButton').innerHTML = 'Sending link...';

    axios.post('/api/password-reset/find', { email: emailField.value })
    .then((result) => {
      if (result.status === 200) {
        alert("Password reset link sent, please check yoru email!")
        window.location.assign('/')
      };
    }, (error) => {
      // UX codes
      document.getElementById('passResetButton').className='animated bounceIn btn btn-danger';
      document.getElementById('passResetButton').innerHTML='ERROR';

      setTimeout(function() {
        document.getElementById('passResetButton').className='btn btn-outline-light';
        document.getElementById('passResetButton').innerHTML='Reset';
        document.getElementById('passResetButton').removeAttribute('disabled');
        document.getElementById('serverValidationStatus').innerHTML = ""
      }, 2000);

      try {
        document.getElementById('serverValidationStatus').innerHTML="Your email does not exist in our system!";
      } catch (error) {};

    });
  };

  resetPassword = (event) => {
    event.preventDefault();

    if (this.state.currentPassword === '') {
      document.getElementById('currentPasswordDidFailValidation').innerHTML = 'Please enter your current password';
      return;
    };

    if (this.state.newPassword === '') {
      document.getElementById('newPasswordDidFailValidation').innerHTML = 'Please enter your new password';
      return;
    };

    if (this.state.password !== this.state.confirmPassword) {
      document.getElementById('confirmPasswordDidFailValidation').innerHTML = 'Password does not match the confirm password';
      return;
    };

    document.getElementById('resetButton').setAttribute('disabled', 'true');
    document.getElementById('resetButton').innerHTML = 'Reset in progress...'
    let newPassword = {};
    newPassword.password = this.state.password;
    newPassword.email = this.state.email;
    newPassword.token = this.state.resetToken;
    axios.post('/api/password-reset/reset', newPassword)
    .then((result) => {
      if (result.status === 200) {
        window.location.assign('/login');
      }
    }, (error) => {
      document.getElementById('resetButton').innerHTML = `${error.message}`;
      document.getElementById('resetButton').className = 'btn btn-danger';
      document.getElementById('resetButton').removeAttribute('disabled');
      setTimeout(function() {
        window.location.reload()
      }, 2000)
    })
  }

  render() {

      if (this.props.isAuthorized) {
        return (<div></div>);
      };

      return (
        <div>
        {this.state.showPassResetRequestInterface &&
        <div id="passResetForm" className="animated fadeIn container text-white">
          <h2>Forgot your password?</h2>
          <hr/>
          <form className="animated slideInUp" onSubmit={this.sendPassResetRequestEmail}>
            <div className="form-group">
              <span className="text-warning" id="serverValidationStatus"></span>
              <input id="emailField" className="container-fluid w-50 form-control" type="email" name="email" value={this.state.email} placeholder="Email address" onChange={this.onChange} autofocus='true'/>
            </div>
            <button id="passResetButton" className="btn btn-outline-light" type="submit">Reset Password</button>
          </form>
          <hr/>
          <p>Need to login? <a className="text-muted" href="/login">Login</a></p>
          <p>Or<a className="text-muted" href="/"> go home</a>.</p>
        </div>
        }
        {this.state.showPasswordResetInterface &&
          <div id="passResetForm" className="animated fadeIn container text-white">
            <h2>Password Reset</h2>
            <hr/>
            <form className="animated slideInUp" onSubmit={this.resetPassword}>
            <div className="form-group">
              <span className="text-danger" id="currentEmailDidFailValidation"></span>
              <input className="container-fluid w-50 form-control" id="currentEmailField" type="email" name="email" placeholder="Current login email" value={this.state.email} onChange={this.onChange}/>
            </div>
            <div className="form-group">
              <span className="text-danger" id="newPasswordDidFailValidation"></span>
              <input className="container-fluid w-50 form-control" id="newPasswordField" type="password" name="password" placeholder="New password" value={this.state.password} onChange={this.onChange}/>
            </div>
            <div className="form-group">
              <span className="text-danger" id="confirmPasswordDidFailValidation"></span>
              <input className="container-fluid w-50 form-control" id="confirmPasswordField" type="password" name="confirmPassword" placeholder="Confirm password" value={this.state.confirmPassword} onChange={this.onChange}/>
            </div>
              <button id="resetButton" className="btn btn-outline-light" type="submit">Reset Password</button>
            </form>
            <hr/>
            <p>Need to login? <a className="text-muted" href="/login">Login</a></p>
            <p>Or<a className="text-muted" href="/"> go home</a>.</p>
          </div>
        }
        </div>
    );
  }
}

export default PassReset;
