import React, { Component } from 'react';
import YouTube from 'react-youtube';
import VimeoPlayer from '@vimeo/player';
import SC from '../../js/SoundCloudAPI';
import ImageGallery from 'react-image-gallery';

import BackOneFrameIcon from './icons/back_one_frame.png'
import ForwardOneFrameIcon from './icons/forward_one_frame.png'
import PlayIcon from './icons/play.png'
import StopIcon from './icons/stop.png'
import ForwardFiveSecondsIcon from './icons/forward_five_seconds.png'
import BackwardFiveSecondsIcon from './icons/backward_five_seconds.png'
import EnlargeWindowIcon from './icons/enlarge.png'
import MinimizeIcon from './icons/minimize.png'
import ReplayIcon from './icons/replay.png'

const QueryString = require('query-string')
const UTIL = require('../UtilityFunction/UtilityFunction');

let currentFlashTimeCode = undefined;

// YouTube player instance
let youtubePlayer;
let userDefinedPlaybackRate = 1;

// Vimeo player instance
let vimeoPlayer;

// SoundCloud player instance
let soundcloudPlayer;
const SOUNDCLOUD_OPTIONS = '&auto_play=true&show_artwork=true&show_comments=false&buying=false&buying=false&sharing=false&enable_api=true&hide_related=true&visual=true';

// global variables to config timecode display
let intervalIdTracker = [];

const TIMECODE_REFRESH_RATE = 10; // the smaller, the faster

class MediaBrowser extends Component {

  constructor(props) {
    super(props);
    this.state = {
      intervalIdTracker: undefined,
      playerReady: false,
      currentPlaybackState: undefined,
      playerHeight: window.innerHeight - 225,
      playerDidEnterFullScreen: true
    };
  };

  componentDidMount() {
    window.addEventListener('resize', () => {
      if (this.state.playerDidEnterFullScreen) {
        this.setState({playerHeight: window.innerHeight - 225});
      }
    })
  }

  componentWillReceiveProps({controlCommand, seconds, speedRate}) {

    if (!this.state.playerReady) {
      this.onVimeoPlayerReady();
      this.onSoundCloudPlayerReady();
    };

    if (controlCommand === 'changeSpeed') {
      this.changeSpeed();
    }

    if (controlCommand === 'jumpToSeconds') {
      this.jumpToSeconds(seconds);
      this.shuttleStop();
    };

    if (controlCommand === 'volumeUp') {
      this.volumeUp();
    };

    if (controlCommand === 'volumeDown') {
      this.volumeDown();
    };

    if (controlCommand === 'playVideo') {
      this.playVideo();
      this.setState({currentComment: undefined});
    };

    if (controlCommand === 'shuttleStop') {
      this.shuttleStop();
    };

    if (controlCommand === 'shuttleRight') {
      this.shuttleRight();
    };

    if (controlCommand === 'shuttleLeft') {
      this.shuttleLeft();
    };

    if (controlCommand === 'stepBackward') {
      this.stepBackward();
    };

    if (controlCommand === 'stepForward') {
      this.stepForward();
    };

    if (controlCommand === 'toggleFullScreen') {
      this.enterFullScreen();
    };

    controlCommand = undefined;
  };

  componentWillUnmount() {
    this.stopTimecode(this.state.intervalIdTracker);
  }

  onYouTubePlayerReady = (event) => {

    // access to player in all event handlers via event.target
    youtubePlayer = event.target;
    intervalIdTracker.push(this.startTimecode(this.props.currentMedia.provider, TIMECODE_REFRESH_RATE));
    this.setState({intervalIdTracker: intervalIdTracker});
    this.playBackFrom(QueryString.parse(window.location.search).t);
  };

  onVimeoPlayerReady = (event) => {
    try {
      if (this.props.currentMedia.provider === 'Vimeo') {
        let options = {
          id: this.props.currentMedia.sourceMedia,
          transparent: 1,
          autoplay: true,
          maxheight: this.state.playerHeight
        };
        vimeoPlayer = new VimeoPlayer('vimeoPlayerWindow', options);
        intervalIdTracker.push(this.startTimecode(this.props.currentMedia.provider, TIMECODE_REFRESH_RATE));
        this.setState({intervalIdTracker: intervalIdTracker});
        this.setState({playerReady: true});
        this.playBackFrom(QueryString.parse(window.location.search).t);
      };
    } catch (error) {};
  };

  onSoundCloudPlayerReady = (event) => {
    if (this.props.currentMedia.provider === 'SoundCloud') {
      try {
        soundcloudPlayer = SC.Widget(document.getElementById('soundcloudPlayerWindow'));
        if (this.props.currentMedia !== undefined) {
          intervalIdTracker.push(this.startTimecode(this.props.currentMedia.provider, TIMECODE_REFRESH_RATE));
          this.setState({intervalIdTracker: intervalIdTracker});
          this.setState({playerReady: true});
          this.playVideo()
        };
      } catch (error) {
        console.log(error);
      };
    };
  };

  onImageGalleryReady = (event) => {
    if (this.state.playerReady === true) {
      if (this.state.slideToIndex !== undefined) {
        this._imageGallery.slideToIndex(this.state.slideToIndex);
      };
      return;
    };

    let urlString = String(window.location.href);
    let url = new URL(urlString);
    let jumpToImage = url.searchParams.get("image");

    if (jumpToImage !== null) {
      this.setState({slideToIndex: this.props.currentMedia.imageArray.map((each) => {return each.id}).indexOf(jumpToImage)})
    };

    try {
      document.getElementById('timecodeDisplay').style = {width: '100%'};
      document.getElementById('timecodeDisplay').innerHTML = String(1 + '/' + this.props.currentMedia.imageArray.length);
      document.getElementById('onSlideImageIdOutput').value = this.props.currentMedia.imageArray[0].id;
      document.getElementById('mediaTitleDisplay').innerHTML = this.props.currentMedia.imageArray[0].title;
    } catch (error) {};

    this.props.currentImageInfo(this.props.currentMedia.imageArray[0].id, this.props.currentMedia.imageArray[0].title);
    window.history.pushState("object or string", "Title", String(window.location.pathname + "?image=" + this.props.currentMedia.imageArray[0].id));

    this.setState({playerReady: true});
  };

  jumpToSeconds = (seconds) => {

    if (this.props.currentMedia.provider === 'YouTube') {
      try {
        youtubePlayer.seekTo(seconds, true);
      } catch (error) {
        console.log(error);
      };
    };

    if (this.props.currentMedia.provider === 'Vimeo') {
      try {
        vimeoPlayer.setCurrentTime(seconds, true);
      } catch (error) {
        console.log(error);
      };
    };

    if (this.props.currentMedia.provider === 'SoundCloud') {
      try {
        soundcloudPlayer.seekTo(seconds*1000);
      } catch (error) {
        console.log(error);
      };
    };
  };

  playBackFrom = (seconds) => {

    if (seconds !== null) {
      // process optional params

      if (isNaN(Number(seconds))) {
        seconds = UTIL.timeParser(seconds);
      };
      this.jumpToSeconds(seconds);
    }

  }

  playVideo = (event) => {

    intervalIdTracker.push(this.startTimecode(this.props.currentMedia.provider, TIMECODE_REFRESH_RATE));
    this.setState({intervalIdTracker: intervalIdTracker});
    this.setState({currentPlaybackState: 'played'});

    document.getElementById('timecodeDisplay').className = 'btn btn-outline-warning';

    if (this.props.currentMedia.provider === 'YouTube') {
      try {

        // when playback has stopped, we don't auto-replay
        // the video from begining
        if (youtubePlayer.getPlayerState() === 0) {
          return;
        };

        youtubePlayer.playVideo();
        youtubePlayer.setPlaybackRate(userDefinedPlaybackRate);
      } catch (error) {
        console.log(error);
      };
    };

    if (this.props.currentMedia.provider === 'Vimeo') {
      try {
        vimeoPlayer.play();
      } catch (error) {
        console.log(error);
      };
    };

    if (this.props.currentMedia.provider === 'SoundCloud') {
      try {
        soundcloudPlayer.play();
      } catch (error) {
        console.log(error);
      };
    };
  };

  changeSpeed = () => {

    if (document.getElementById('timecodeDisplay').className === 'btn btn-danger') {
      document.getElementById('timecodeDisplay').className='btn btn-outline-warning';
      return;
    };

    if (this.props.currentMedia.provider === 'YouTube') {
      try {

        if (userDefinedPlaybackRate <= 1.5) {
          userDefinedPlaybackRate = youtubePlayer.getPlaybackRate() + 0.5
        } else {
          userDefinedPlaybackRate = 1;
        }

        document.getElementById('timecodeDisplay').innerHTML = String(UTIL.convertToTimecode(youtubePlayer.getCurrentTime()) + ' | ' + youtubePlayer.getPlaybackRate() + 'x');
        youtubePlayer.setPlaybackRate(userDefinedPlaybackRate);

      } catch (error) {
        console.log(error);
      };
    };

  }

  volumeUp = (event) => {
    if (this.props.currentMedia.provider === 'YouTube') {
      try {
        youtubePlayer.unMute();
        youtubePlayer.setVolume(youtubePlayer.getVolume() + 10);
      } catch (error) {
        console.log(error);
      };
    };
    if (this.props.currentMedia.provider === 'Vimeo') {
      try {
        vimeoPlayer.getVolume().then(function(volume) {
          vimeoPlayer.setVolume(volume + 0.1);
        }).catch(function(error) {
          console.log(error);
        });
      } catch (error) {
        console.log(error);
      };
    };
  };

  volumeDown = (event) => {
    if (this.props.currentMedia.provider === 'YouTube') {
      try {
        youtubePlayer.setVolume(youtubePlayer.getVolume() - 10);
      } catch (error) {
        console.log(error);
      };
    };
    if (this.props.currentMedia.provider === 'Vimeo') {
      try {
        vimeoPlayer.getVolume().then(function(volume) {
          vimeoPlayer.setVolume(volume - 0.1);
        }).catch(function(error) {
          console.log(error);
        });
      } catch (error) {
        console.log(error);
      };
    };
  };

  shuttleRight = (event) => {

    intervalIdTracker.push(this.startTimecode(this.props.currentMedia.provider, TIMECODE_REFRESH_RATE));
    this.setState({intervalIdTracker: intervalIdTracker});
    this.setState({currentPlaybackState: 'played'});
    document.getElementById('timecodeDisplay').className = 'btn btn-outline-warning';

    if (this.props.currentMedia.provider === 'YouTube') {
      try {
        if (youtubePlayer.getPlayerState() === 1) {
          youtubePlayer.playVideo();
          if (userDefinedPlaybackRate <= 1.5) {
            userDefinedPlaybackRate = youtubePlayer.getPlaybackRate() + 0.5
          } else {
            userDefinedPlaybackRate = 1;
          }
          youtubePlayer.setPlaybackRate(userDefinedPlaybackRate);
        };
        youtubePlayer.playVideo();
      } catch (error) {
        console.log(error);
      };
    };

    if (this.props.currentMedia.provider === 'Vimeo') {
      try {
        vimeoPlayer.getCurrentTime().then(function(seconds){
          vimeoPlayer.play();
          vimeoPlayer.setCurrentTime(seconds + 5);
        }).catch(function(error) {
          console.log(error);
        });
      } catch (error) {
        console.log(error);
      };
    };

    if (this.props.currentMedia.provider === 'SoundCloud') {
      try {
        soundcloudPlayer.pause();
        soundcloudPlayer.getPosition(function(milliseconds) {
          soundcloudPlayer.seekTo(milliseconds + 5000);
        });
      } catch (error) {
        console.log(error);
      };
    };
  };

  shuttleLeft = (event) => {

    this.stopTimecode(this.state.intervalIdTracker);
    this.updateTimecode(this.props.currentMedia.provider);
    document.getElementById('timecodeDisplay').className = 'btn btn-danger';
    this.setState({currentPlaybackState: 'stopped'});

    if (this.props.currentMedia.provider === 'YouTube') {
      try {
        youtubePlayer.pauseVideo();
        youtubePlayer.seekTo(youtubePlayer.getCurrentTime() - 5);
      } catch (error) {
        console.log(error);
      };
    };

    if (this.props.currentMedia.provider === 'Vimeo') {
      try {
        vimeoPlayer.pause();
        vimeoPlayer.getCurrentTime().then(function(seconds){
        vimeoPlayer.setCurrentTime(seconds - 5);
        }).catch(function(error) {
          console.log(error);
        });
      } catch (error) {
        console.log(error);
      };
    };

    if (this.props.currentMedia.provider === 'SoundCloud') {
      try {
        soundcloudPlayer.pause();
        soundcloudPlayer.getPosition(function(milliseconds) {
          soundcloudPlayer.seekTo(milliseconds - 5000);
        });
      } catch (error) {
        console.log(error);
      };
    };
  };

  shuttleStop = (event) => {
    this.stopTimecode(this.state.intervalIdTracker);
    this.updateTimecode(this.props.currentMedia.provider);
    this.setState({currentPlaybackState: 'stopped'});

    document.getElementById('timecodeDisplay').className = 'btn btn-danger';

    if (this.props.currentMedia.provider === 'YouTube') {
      try {
        youtubePlayer.pauseVideo();
      } catch (error) {
        console.log(error);
      };
    };

    if (this.props.currentMedia.provider === 'Vimeo') {
      try {
        vimeoPlayer.pause();
      } catch (error) {
        console.log(error);
      };
    };

    if (this.props.currentMedia.provider === 'SoundCloud') {
      try {
        soundcloudPlayer.pause();
      } catch (error) {
        console.log(error);
      };
    };
  };

  jumpForward = (event) => {

    this.stopTimecode(this.state.intervalIdTracker);
    this.setState({currentPlaybackState: 'stopped'});
    document.getElementById('timecodeDisplay').className = 'btn btn-danger';

    if (this.props.currentMedia.provider === 'YouTube') {
      try {
        youtubePlayer.seekTo(youtubePlayer.getCurrentTime() + 5);
        youtubePlayer.pauseVideo();
        this.updateTimecode(this.props.currentMedia.provider, 5);
      } catch (error) {
        console.log(error);
      };
    };

    if (this.props.currentMedia.provider === 'Vimeo') {
      try {
        vimeoPlayer.pause();
        vimeoPlayer.getCurrentTime().then(function(seconds){
        vimeoPlayer.setCurrentTime(seconds + 5);
        }).catch(function(error) {
          console.log(error);
        });
        this.updateTimecode(this.props.currentMedia.provider, 5);
      } catch (error) {
        console.log(error);
      };
    };

    if (this.props.currentMedia.provider === 'SoundCloud') {
      try {
        soundcloudPlayer.pause();
        soundcloudPlayer.getPosition(function(milliseconds) {
          soundcloudPlayer.seekTo(milliseconds + 5000);
        });
        this.updateTimecode(this.props.currentMedia.provider, 5000);
      } catch (error) {
        console.log(error);
      };
    };
  }

  jumpBackward = (event) => {

    this.stopTimecode(this.state.intervalIdTracker);
    this.setState({currentPlaybackState: 'stopped'});
    document.getElementById('timecodeDisplay').className = 'btn btn-danger';

    if (this.props.currentMedia.provider === 'YouTube') {
      try {
        youtubePlayer.seekTo(youtubePlayer.getCurrentTime() - 5);
        youtubePlayer.pauseVideo();
        this.updateTimecode(this.props.currentMedia.provider, -5);
      } catch (error) {
        console.log(error);
      };
    };

    if (this.props.currentMedia.provider === 'Vimeo') {
      try {
        vimeoPlayer.pause();
        vimeoPlayer.getCurrentTime().then(function(seconds){
        vimeoPlayer.setCurrentTime(seconds - 5);
        }).catch(function(error) {
          console.log(error);
        });
        this.updateTimecode(this.props.currentMedia.provider, -5);
      } catch (error) {
        console.log(error);
      };
    };

    if (this.props.currentMedia.provider === 'SoundCloud') {
      try {
        soundcloudPlayer.pause();
        soundcloudPlayer.getPosition(function(milliseconds) {
          soundcloudPlayer.seekTo(milliseconds - 5000);
        });
        this.updateTimecode(this.props.currentMedia.provider, -5000);
      } catch (error) {
        console.log(error);
      };
    };

  }

  stepForward = (event) => {

    this.stopTimecode(this.state.intervalIdTracker);
    this.updateTimecode(this.props.currentMedia.provider);
    document.getElementById('timecodeDisplay').className = 'btn btn-danger';
    this.setState({currentPlaybackState: 'stopped'});

    if (this.props.currentMedia.provider === 'YouTube') {
      try {
        youtubePlayer.pauseVideo();
        youtubePlayer.seekTo((youtubePlayer.getCurrentTime() + 0.03), true);
      } catch (error) {
        console.log(error);
      };
    };

    if (this.props.currentMedia.provider === 'Vimeo') {
      try {
        vimeoPlayer.pause();
        vimeoPlayer.getCurrentTime().then(function(seconds){
          vimeoPlayer.setCurrentTime(seconds + 0.03);
        }).catch(function(error) {
          console.log(error);
        });
        document.getElementById('timecodeDisplay').className = 'btn btn-danger';
      } catch (error) {
        console.log(error);
      };
    };

    if (this.props.currentMedia.provider === 'SoundCloud') {
      try {
        soundcloudPlayer.pause();
        soundcloudPlayer.getPosition(function(milliseconds) {
          soundcloudPlayer.seekTo(milliseconds + 30);
        });
      } catch (error) {
        console.log(error);
      };
    };
  };

  stepBackward = (event) => {

    this.stopTimecode(this.state.intervalIdTracker);
    this.updateTimecode(this.props.currentMedia.provider);
    document.getElementById('timecodeDisplay').className = 'btn btn-danger';
    this.setState({currentPlaybackState: 'stopped'});

    if (this.props.currentMedia.provider === 'YouTube') {
      try {
        youtubePlayer.pauseVideo();
        youtubePlayer.seekTo((youtubePlayer.getCurrentTime() - 0.03), true);
      } catch (error) {
        console.log(error);
      };
    };

    if (this.props.currentMedia.provider === 'Vimeo') {
      try {
        vimeoPlayer.pause();
        vimeoPlayer.getCurrentTime().then(function(seconds){
          vimeoPlayer.setCurrentTime(seconds - 0.03);
        }).catch(function(error) {
          console.log(error);
        });
      } catch (error) {
        console.log(error);
      };
    };

    if (this.props.currentMedia.provider === 'SoundCloud') {
      try {
        soundcloudPlayer.pause();
        soundcloudPlayer.getPosition(function(milliseconds) {
          soundcloudPlayer.seekTo(milliseconds - 30);
        });
      } catch (error) {
        console.log(error);
      };
    };
  };

  startTimecode = (mediaType, refreshRate) => {

    if (mediaType === 'SoundCloud') {
      if (soundcloudPlayer !== undefined) {
        try {
          return setInterval(function() {
            soundcloudPlayer.getPosition(function(milliseconds) {
              try {
                document.getElementById('timecodeOutput').value = milliseconds/1000;
                document.getElementById('timecodeDisplay').innerHTML = UTIL.convertToTimecode(milliseconds/1000);
              } catch (error) {};
              try {
                let flashTimeCode = document.getElementById('commentAtSecond' + UTIL.convertToTimecode(milliseconds/1000));
                if (currentFlashTimeCode === flashTimeCode) {
                  return;
                };
                currentFlashTimeCode = flashTimeCode;
                if (flashTimeCode.innerText === '00:00:00:00') {
                  return;
                };
                if (flashTimeCode !== null) {
                  document.getElementById('commentTableScroll').scrollTop = document.getElementById('commentRow' + flashTimeCode.getAttribute('data-commentId')).offsetTop - 50;
                  flashTimeCode.className='animated fadeIn bg-warning text-dark';
                  setTimeout(function() {
                    flashTimeCode.className='text-white';
                  },3000);
                }
              } catch (error) {};
            });
          }, TIMECODE_REFRESH_RATE);
        } catch (error) {
          console.log(error);
        };
      };
    };

    if (mediaType === 'YouTube') {
      if (youtubePlayer !== undefined) {
        try {
          return setInterval(function() {
            let currentTime = youtubePlayer.getCurrentTime();
            try {
              document.getElementById('timecodeOutput').value = currentTime;
              document.getElementById('timecodeDisplay').innerHTML = UTIL.convertToTimecode(youtubePlayer.getCurrentTime());
              document.getElementById('timecodeDisplay').innerHTML += String(' | ' + youtubePlayer.getPlaybackRate() + 'x');
            } catch (error) {}
            try {
              let flashTimeCode = document.getElementById('commentAtSecond' + UTIL.convertToTimecode(currentTime));
              if (currentFlashTimeCode === flashTimeCode) {
                return;
              };
              currentFlashTimeCode = flashTimeCode;
              if (flashTimeCode.innerText === '00:00:00:00') {
                return;
              };
              if (flashTimeCode !== null) {
                document.getElementById('commentTableScroll').scrollTop = document.getElementById('commentRow' + flashTimeCode.getAttribute('data-commentId')).offsetTop - 50;
                flashTimeCode.className='animated fadeIn bg-warning text-dark';
                setTimeout(function() {
                  flashTimeCode.className='text-white';
                },3000);
              }
            } catch (error) {};
          }, TIMECODE_REFRESH_RATE);
        } catch (error) {
          console.log(error);
        };
      };
    };

    if (mediaType === 'Vimeo') {
      if (vimeoPlayer !== undefined) {
        try {
          return setInterval(function() {
            vimeoPlayer.getCurrentTime().then(function(seconds) {
              document.getElementById('timecodeOutput').value = seconds;
              document.getElementById('timecodeDisplay').innerHTML = UTIL.convertToTimecode(seconds);
              try {
                let flashTimeCode = document.getElementById('commentAtSecond' + UTIL.convertToTimecode(seconds));
                if (currentFlashTimeCode === flashTimeCode) {
                  return;
                };
                currentFlashTimeCode = flashTimeCode;
                if (flashTimeCode.innerText === '00:00:00:00') {
                  return;
                };
                if (flashTimeCode !== null) {
                  document.getElementById('commentTableScroll').scrollTop = document.getElementById('commentRow' + flashTimeCode.getAttribute('data-commentId')).offsetTop - 50;
                  flashTimeCode.className='animated fadeIn bg-warning text-dark';
                  setTimeout(function() {
                    flashTimeCode.className='text-white';
                  },3000);
                }
              } catch (error) {};
            }).catch(function(error) {
              console.log(error);
            });
          }, refreshRate);
        } catch (error) {
          console.log(error);
        };
      };
    };
  };

  updateTimecode = (mediaType, offset=0) => {

    if (mediaType === 'SoundCloud') {
      if (soundcloudPlayer !== undefined) {
        try {
            soundcloudPlayer.getPosition(function(milliseconds) {
              document.getElementById('timecodeOutput').value = (milliseconds + offset)/1000;
              document.getElementById('timecodeDisplay').innerHTML = UTIL.convertToTimecode((milliseconds + offset)/1000);
            });
        } catch (error) {
          console.log(error);
        };
      };
    };

    if (mediaType === 'YouTube') {
      try {
          document.getElementById('timecodeOutput').value = youtubePlayer.getCurrentTime() + offset;
          document.getElementById('timecodeDisplay').innerHTML = UTIL.convertToTimecode(youtubePlayer.getCurrentTime() + offset);
      } catch (error) {
        console.log(error);
      };
    };

    if (mediaType === 'Vimeo') {
      try {
          vimeoPlayer.getCurrentTime().then(function(seconds) {
            document.getElementById('timecodeOutput').value = seconds + offset;
            document.getElementById('timecodeDisplay').innerHTML = UTIL.convertToTimecode(seconds + offset);
          }).catch(function(error) {
            console.log(error);
          });
      } catch (error) {
        console.log(error);
      };
    };
  };

  stopTimecode = (timecodeIntervalIdArray) => {
    if (timecodeIntervalIdArray === undefined) {
      return;
    };
    timecodeIntervalIdArray.map(function(each) {
      clearInterval(each);
      return each;
    });
  };

  syncControl = () => {
    if (youtubePlayer.getPlayerState() === 1) {
      this.setState({currentPlaybackState: 'played'});
    } else {
      this.setState({currentPlaybackState: 'stopped'});
    }
  }

  enterFullScreen = () => {

    if (this.state.playerDidEnterFullScreen) {
      this.setState({playerHeight: 540});
      this.setState({playerDidEnterFullScreen: false});
      document.getElementById('commentField').focus();

    } else {

      if (window.innerHeight > 2000) {
        this.setState({playerHeight: 2000 - 225});
      } else {
        this.setState({playerHeight: window.innerHeight - 225});
      }

      this.setState({playerDidEnterFullScreen: true});
      document.getElementById('commentField').focus();
    }
  }

  handleImageComment = (event) => {
    // lifting up the state
    this.props.currentImageInfo(this.props.currentMedia.imageArray[event].id, this.props.currentMedia.imageArray[event].title);
    window.history.pushState("object or string", "Title", String(window.location.pathname + "?image=" + this.props.currentMedia.imageArray[event].id));
    document.getElementById('timecodeDisplay').style = {width: '100%'};
    document.getElementById('timecodeDisplay').innerHTML = String(event + 1 + '/' + this.props.currentMedia.imageArray.length);
    document.getElementById('onSlideImageIdOutput').value = this.props.currentMedia.imageArray[event].id;
    document.getElementById('mediaTitleDisplay').innerHTML = this.props.currentMedia.imageArray[event].title;
    this.setState({slideToIndex: undefined});
  };

  render() {

    if (this.props.currentMedia.provider === 'dropbox') {

      // initialize images (an array type) for our react-gallery
      let images = [];

      for (let i = 0; i < this.props.currentMedia.imageArray.length; i++) {
        let tempObject = {};
        tempObject.original = this.props.currentMedia.imageArray[i].mediaUrl;
        tempObject.thumbnail = this.props.currentMedia.imageArray[i].thumbnailUrl;
        tempObject.title = this.props.currentMedia.imageArray[i].title;
        images.push(tempObject);
      };

      return (
        <div className='container-fluid'>
          <div id="imageGalleryDisplayWrapper" className="text-white container">
            <span id="mediaTitleDisplay" style={{marginLeft: 18, marginBottom: 10}} className="text-white"></span>
            <ImageGallery id="imageGalleryDisplay" ref={i => this._imageGallery = i} items={images} onImageLoad={this.onImageGalleryReady} onSlide={this.handleImageComment} />
            <input id='onSlideImageIdOutput' hidden type='string'/>
          </div>
        </div>
      );
    };

    if (this.props.currentMedia.provider === 'SoundCloud') {

      return (
        <div>

          <div className="row container-fluid">
            <span id="mediaTitleDisplay" style={{marginLeft: 18, marginBottom: 10}} className="text-left text-white">{this.props.currentMedia.title}</span>
          </div>

          <div className="text-white" style={{height:540, background:'#000000'}}>
            <iframe id="soundcloudPlayerWindow" title="soundcloud player" allow="autoplay" width="100%" height="540" scrolling="no" frameBorder="no" src={this.props.currentMedia.mediaUrl + SOUNDCLOUD_OPTIONS}></iframe>
            <input id="timecodeOutput" hidden type="number"/>
          </div>

          <div className="row text-white">
            <div className="col">
              <div style={{height: 10}}/>
              <img src={ReplayIcon} style={{cursor: "pointer"}} onClick={() => {this.jumpToSeconds(0); this.playVideo()}} alt="replay_video_from_beginning"/>
              <span>&nbsp;&nbsp;&nbsp;</span>
              <img src={BackOneFrameIcon} style={{cursor: "pointer"}} onClick={this.stepBackward} alt="back_one_frame"/>
              <span>&nbsp;&nbsp;&nbsp;</span>
              <img src={BackwardFiveSecondsIcon} style={{cursor: "pointer"}} onClick={this.jumpBackward} alt="backward_five_seconds"/>
              <span>&nbsp;&nbsp;&nbsp;</span>
              {this.state.currentPlaybackState !== "played"
              ? <img src={PlayIcon} style={{cursor: "pointer"}} onClick={this.playVideo} alt="play"/>
              : <img src={StopIcon} style={{cursor: "pointer"}} onClick={this.shuttleStop} alt="stop"/>
              }
              <span>&nbsp;&nbsp;&nbsp;</span>
              <img src={ForwardFiveSecondsIcon} style={{cursor: "pointer"}} onClick={this.jumpForward} alt="forward_five_seconds"/>
              <span>&nbsp;&nbsp;&nbsp;</span>
              <img src={ForwardOneFrameIcon} style={{cursor: "pointer"}} onClick={this.stepForward} alt="forward_one_frame"/>
            </div>
          </div>

        </div>
      );
    };

    if (this.props.currentMedia.provider === "YouTube") {

      document.getElementById('timecodeDisplay').style.width = '170px';

      // https://developers.google.com/youtube/player_parameters
      let YOUTUBE_OPTIONS = {
        height: '100%',
        width: '100%',
        playerVars: {
          frameBorder: 0,
          autoplay: 1,
          rel: 0,
          showinfo: 0,
          controls: 1,
          modestbranding: 1,
          loop: 1,
          playsinline: 1
        }
      };

      return (

        <div>

          <div className="row container-fluid">
            <span id="mediaTitleDisplay" style={{marginLeft: 18, marginBottom: 10}} className="text-left text-white">{this.props.currentMedia.title}</span>
          </div>

          <div className="text-white container-fluid" id="mediaViewWindow" style={{position: 'relative', height: this.state.playerHeight, background:'#000000'}}>
            <YouTube videoId={this.props.currentMedia.sourceMedia}  opts={YOUTUBE_OPTIONS} onReady={this.onYouTubePlayerReady} onStateChange={this.syncControl}/>
            <input id="timecodeOutput" hidden type="number"/>
          </div>

          <div className="row text-white container-fluid">
            <div className="col">
              <div style={{height: 10}}/>
              <img src={ReplayIcon} style={{cursor: "pointer"}} onClick={() => {this.jumpToSeconds(0); this.playVideo()}} alt="replay_video_from_beginning"/>
              <span>&nbsp;&nbsp;&nbsp;</span>
              <img src={BackOneFrameIcon} style={{cursor: "pointer"}} onClick={this.stepBackward} alt="back_one_frame"/>
              <span>&nbsp;&nbsp;&nbsp;</span>
              <img src={BackwardFiveSecondsIcon} style={{cursor: "pointer"}} onClick={this.jumpBackward} alt="backward_five_seconds"/>
              <span>&nbsp;&nbsp;&nbsp;</span>
              {this.state.currentPlaybackState !== "played"
              ? <img src={PlayIcon} style={{cursor: "pointer"}} onClick={this.playVideo} alt="play"/>
              : <img src={StopIcon} style={{cursor: "pointer"}} onClick={this.shuttleStop} alt="stop"/>
              }
              <span>&nbsp;&nbsp;&nbsp;</span>
              <img src={ForwardFiveSecondsIcon} style={{cursor: "pointer"}} onClick={this.jumpForward} alt="forward_five_seconds"/>
              <span>&nbsp;&nbsp;&nbsp;</span>
              <img src={ForwardOneFrameIcon} style={{cursor: "pointer"}} onClick={this.stepForward} alt="forward_one_frame"/>
              <span>&nbsp;&nbsp;&nbsp;</span>
              <img src={this.state.playerDidEnterFullScreen ? MinimizeIcon : EnlargeWindowIcon} style={{cursor: "pointer"}} alt="enter_full_screen" onClick={this.enterFullScreen}/>
            </div>
          </div>

        </div>
      )
    }

    if (this.props.currentMedia.provider === "Vimeo") {
      return (
        <div>

          <div className="row container-fluid">
            <span id="mediaTitleDisplay" style={{marginLeft: 18, marginBottom: 10}} className="text-left text-white">{this.props.currentMedia.title}</span>
          </div>

          <div className="text-white container-fluid" id="mediaViewWindow" style={{position: 'relative', height: this.state.playerHeight, background:'#000000'}}>
            <div>
              <div id="vimeoPlayerWindow"/>
              <input hidden id="timecodeOutput"  type="number"/>
            </div>
          </div>

          <div className="row text-white">
            <div className="col">
              <div style={{height: 10}}/>
              <img src={ReplayIcon} style={{cursor: "pointer"}} onClick={() => {this.jumpToSeconds(0); this.playVideo()}} alt="replay_video_from_beginning"/>
              <span>&nbsp;&nbsp;&nbsp;</span>
              <img src={BackOneFrameIcon} style={{cursor: "pointer"}} onClick={this.stepBackward} alt="back_one_frame"/>
              <span>&nbsp;&nbsp;&nbsp;</span>
              <img src={BackwardFiveSecondsIcon} style={{cursor: "pointer"}} onClick={this.jumpBackward} alt="backward_five_seconds"/>
              <span>&nbsp;&nbsp;&nbsp;</span>
              {this.state.currentPlaybackState !== "played"
              ? <img src={PlayIcon} style={{cursor: "pointer"}} onClick={this.playVideo} alt="play"/>
              : <img src={StopIcon} style={{cursor: "pointer"}} onClick={this.shuttleStop} alt="stop"/>
              }
              <span>&nbsp;&nbsp;&nbsp;</span>
              <img src={ForwardFiveSecondsIcon} style={{cursor: "pointer"}} onClick={this.jumpForward} alt="forward_five_seconds"/>
              <span>&nbsp;&nbsp;&nbsp;</span>
              <img src={ForwardOneFrameIcon} style={{cursor: "pointer"}} onClick={this.stepForward} alt="forward_one_frame"/>
            </div>
          </div>
        </div>
      );
    };

    return (
      <div className="text-white">Error initializing MediaBrowser</div>
    );
  };
};

export default MediaBrowser;
