import React, { Component } from 'react';
import axios from 'axios';

class EditProject extends Component {

  constructor(props) {
    super(props);
    this.state = {
      projectName: this.props.currentProject.name,
      projectDescription: this.props.currentProject.description
    };
  };

  autofocusInput = (event) => {
    setTimeout(function() {
      try {
        document.getElementById('projectName').focus();
      } catch (error) {};
    }, 500);
  };

  onChange = (event) => {
    event.preventDefault();
    this.setState({ [event.target.name]: event.target.value });
    document.getElementById('saveButton' + this.props.currentProject.id).className = 'btn btn-primary';
    document.getElementById('saveButton' + this.props.currentProject.id).innerHTML = 'Save';
  };

  onSubmit = (event) => {
    event.preventDefault();

    let editProjectNameField = document.getElementById('editProjectName');

    if (editProjectNameField.value === '') {
      editProjectNameField.className += ' animated bounceIn'
      return;
    };

    let projectToUpdate = {
      name: this.state.projectName,
      description: this.state.projectDescription
    };

    document.getElementById('saveButton' + this.props.currentProject.id).setAttribute('disabled', 'true');
    document.getElementById('saveButton' + this.props.currentProject.id).innerHTML = 'Saving...';

    axios.patch('/api/project/' + this.props.currentProject.id, projectToUpdate)
    .then((result) => {
      if (result.status === 200) {
        window.location.reload();
      }
    }, (error) => {
      console.log(error);
      document.getElementById('saveButton' + this.props.currentProject.id).className = 'btn btn-danger';
      document.getElementById('saveButton' + this.props.currentProject.id).innerHTML = 'Failed';
      document.getElementById('saveButton' + this.props.currentProject.id).removeAttribute('disabled');
    });
  };

  render() {

    let { projectName, projectDescription } = this.state;

    return (
      <span>

        <span>
          <i className="far fa-edit" style={{cursor: "pointer"}} data-toggle="modal" data-target={"#EditProjectModal" + this.props.currentProject.id} onClick={this.autofocusInput}/>
        </span>

        <div className="modal text-dark fade" id={"EditProjectModal" + this.props.currentProject.id} style={{backgroundColor:'rgba(0, 0, 0, 0.5)'}} data-backdrop="false" tabIndex="-1" role="dialog" aria-labelledby="EditProjectModalTitle" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="EditProjectModalTitle">Edit Project</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
              <form id="EditProjectForm" onSubmit={this.onSubmit}>
                <div className="form-group">
                  <input className="form-control" id="editProjectName" type="text" name="projectName" placeholder={"Name"} value={projectName} onChange={this.onChange}/>
                </div>
                <div className="form-group">
                  <input className="form-control" type="text" name="projectDescription" placeholder="Description (optional)" value={projectDescription} onChange={this.onChange}/>
                </div>
                <div className="modal-footer">
                  <button id={'saveButton' + this.props.currentProject.id} type="submit" className="btn btn-primary">Save</button>
                  <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancel</button>
                </div>
              </form>
              </div>
            </div>
          </div>
        </div>
      </span>
    )
  }
}

export default EditProject;
