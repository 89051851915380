import React, { Component } from 'react';
import axios from 'axios';

import CreateProject from './CreateProject';
import EditProject from './EditProject';
import LeaveProject from './LeaveProject';
import DeleteProject from './DeleteProject';
import Invitation from './Invitation';

const UTIL = require('../../UtilityFunction/UtilityFunction');

let projectsBuffer = [];
let searchBuffer = [];
let isSearching = false;

class ProjectLibrary extends Component {

  constructor() {
    super();
    this.state = {
      updateContent: undefined
    };

    this.handleCreateProject = this.handleCreateProject.bind(this);
    this.handleDeleteProject = this.handleDeleteProject.bind(this);
  }

  componentDidMount() {
    setTimeout(function() {
      this.fetchProjects();
    }.bind(this), 100);
  };

  fetchProjects = (next) => {
    axios.get('/api/project')
    .then((result) => {
      if (result.data !== undefined) {
        projectsBuffer = result.data.map((each) => {
          if (each.mediaArray === undefined) {
            each.mediaArray = [];
          }
          return each;
        });
        this.fetchCollaboratingProjects();
      }
    }, (error) => {
      console.log(error);
    });
  }

  fetchCollaboratingProjects = () => {
    axios.get('/api/project/collaboration/' + this.props.currentUser.id)
    .then((result) => {
      if (result.data !== undefined) {
        result.data.map((each) => {
          if (each.mediaArray === undefined) {
            each.mediaArray = [];
          }
          projectsBuffer.push(each);
          return each;
        });
        UTIL.sortByKey(projectsBuffer, 'name', 'ascend');
        this.setState({updateContent: true});
      };
    }, (error) => {
      console.log(error);
    });
  };

  handleDeleteProject = (deletedProjectId) => {
    projectsBuffer = projectsBuffer.filter((function(each) {
      return each.id !== deletedProjectId;
    }));
    this.setState({updateContent: true});
  }

  handleCreateProject = (project) => {
    projectsBuffer.push(project);
    this.setState({updateContent: true});
  }

  filterProject = (event) => {

    if (event.target.value === '') {
      isSearching = false;
      this.setState({updateContent: true});
      return;
    };

    searchBuffer = projectsBuffer.slice(0);

    searchBuffer = searchBuffer.filter((each) => {
      return (each.name.toLowerCase()).includes(event.target.value.toLowerCase());
    });

    isSearching = true;
    this.setState({updateContent: true});
  };

  render() {

    if (!isSearching) {
      // can't use slice(0) here, sorting won't work otherwise
      searchBuffer = projectsBuffer;
    };

    return (
        <div>

        {!this.state.updateContent &&
          <div className="spinner">
            <div className="double-bounce1"></div>
            <div className="double-bounce2"></div>
          </div>
        }

        {this.state.updateContent &&
          <div className="animated fadeIn container-fluid text-white">
            <div>
              <div className="row">
                <span className="col d-flex justify-content-start text-white"><h3>Project Library</h3></span>
                <span className="col container-fluid d-flex justify-content-end">
                  <div className="row" style={{marginRight:1}}>
                  <input className="col-4 form-control" type="text" style={{marginRight: "15px"}} placeholder="Search" onChange={this.filterProject}/>
                  <span>&nbsp;&nbsp;</span>
                  <CreateProject onCreateProject={this.handleCreateProject}/>
                  <span>&nbsp;&nbsp;</span>
                  <Invitation currentUser={this.props.currentUser}/>
                  </div>
                </span>
              </div>
              <br/>
            </div>

            <table className="table table-striped table-hover">
              <thead className="thead-dark">
                <tr>
                  <th style={{width:'20%'}} scope="col" className="text-left">Project Title</th>
                  <th style={{width:'35%'}} scope="col" className="text-left">Description</th>
                  <th style={{width:'15%'}} scope="col" className="text-center">Media</th>
                  <th style={{width:'15%'}} scope="col" className="text-center">Collaborator(s)</th>
                  <th style={{width:'5%'}} scope="col" className="text-center">Action</th>
                  <th style={{width:'10%'}} scope="col">Created by</th>
                </tr>
              </thead>
            </table>

            <div style={{display: "block", maxHeight:'400px', overflowY: 'auto', overflowX: 'hidden'}}>
              <table className="animated fadeIn table table-striped table-hover">
                <tbody>
                {projectsBuffer.length === 0 &&
                  <div>No project in project library</div>
                }
                {searchBuffer.map((each) => (
                    <tr id={"projectRow" + each.id} key={each.id}>
                      <td style={{width:'20%'}} className="text-left">
                        <a className="text-white" href={"/project/" + each.id}>{each.name}</a>
                        {this.props.currentUser.id !== each.owner.id &&
                          <span>
                            <span>&nbsp;</span>
                            <i className="fab fa-slideshare"></i>
                          </span>
                        }
                      </td>
                      <td style={{width:'35%'}} className="text-left">{each.description}</td>
                      <td style={{width:'15%'}}>{each.mediaArray.length}</td>
                      <td style={{width:'15%'}}>{each.collaborators.length}</td>
                      <td style={{width:'5%'}}>
                        {this.props.currentUser.id === each.owner.id &&
                          <span>
                          <EditProject currentProject={each} />
                          <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                          <DeleteProject currentProject={each} onDeleteProject={this.handleDeleteProject} />
                          </span>
                        }
                        {this.props.currentUser.id !== each.owner.id &&
                          <span>
                            <LeaveProject currentProject={each}/>
                          </span>
                        }
                      </td>
                      <td style={{width:'10%'}}>{each.owner.userName}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
          </div>
        </div>
      }
      </div>
    )
  }
}

export default ProjectLibrary;
