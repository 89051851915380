import React, { Component } from 'react';

class Sharing extends Component {

  constructor(props) {
    super(props);
    this.state = {
      shareLink: window.location.href
    };
  };

  copyToClipBoard = (event) => {

    this.setImageGalleryToBack();

    let copyText = document.getElementById('shareLink');
    if (this.props.inActionModule) {
      copyText.value = (window.location.host + '/' + (this.props.currentMedia.type !== 'Album' ? 'media/' : 'album/') + this.props.currentMedia.id);
    };
    copyText.select();
    document.execCommand('copy');
    setTimeout(function() {
      copyText.focus();
      copyText.select();
    }, 100);
  }

  resetImageGalleryZIndex = () => {
    try {
      document.getElementById('imageGalleryDisplayWrapper').style.zIndex = 1;
    } catch (error) {};
  };

  setImageGalleryToBack = () => {
    try {
      document.getElementById('imageGalleryDisplayWrapper').style.position = 'relative';
      document.getElementById('imageGalleryDisplayWrapper').style.zIndex = -1;
    } catch (error) {};
  }

  render() {

    return (
      <span>

        <span>
          <button type="button" className="btn btn-outline-light" data-toggle="modal" data-target="#shareLinkModal" onClick={this.copyToClipBoard}><i className="far fa-share-square"/></button>
        </span>

        <div className="modal text-dark fade" id="shareLinkModal" style={{backgroundColor:'rgba(0, 0, 0, 0.5)'}} data-backdrop="false" tabIndex="-1" role="dialog" aria-labelledby="shareLinkModalTitle" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="shareLinkModalTitle">Share Link</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="form-group">
                  <small>'The link has been copied to your clipboard!'</small>
                  <input readOnly className="form-control" id="shareLink" type="text" name="shareLink" value={window.location.href}/>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={this.resetImageGalleryZIndex}>OK</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </span>
    )
  }
}

export default Sharing;
