import React, { Component } from 'react';
import axios from 'axios';

class CreateProject extends Component {

  constructor(props) {
    super(props);
    this.state = {
      projectName: undefined,
      projectDescription: undefined
    };
  };

  autofocusInput = (event) => {
    setTimeout(function() {
      document.getElementById('projectName').focus();
    }, 500)
  };

  onChange = (event) => {
    event.preventDefault();
    this.setState({ [event.target.name]: event.target.value });
    document.getElementById('createProjectButton').innerHTML = 'Create';
    document.getElementById('createProjectButton').className = 'btn btn-primary';
  };

  onSubmit = (event) => {
    event.preventDefault();

    let projectNameField = document.getElementById('projectName');
    let projectDescriptionField = document.getElementById('projectDescription');

    if (projectNameField.value === '') {
      projectNameField.className += ' animated bounceIn'
      return;
    };

    let projectToCreate = {
      name: this.state.projectName,
      description: this.state.projectDescription
    };

    document.getElementById('createProjectButton').setAttribute('disabled', 'true');
    document.getElementById('createProjectButton').innerHTML = 'Creating...';

    axios.post('/api/project/create', projectToCreate)
    .then((result) => {
      if (result.status === 200) {
        this.onCreateProject(result.data);
        projectNameField.value = '';
        projectDescriptionField.value = '';
        document.getElementById('closeCreateProjectModalButton').click();
        document.getElementById('createProjectButton').removeAttribute('disabled');
        document.getElementById('createProjectButton').innerHTML = 'Create';
      }
    }, (error) => {
      console.log(error);
      document.getElementById('createProjectButton').removeAttribute('disabled');
      document.getElementById('createProjectButton').className = 'btn btn-danger';
      document.getElementById('createProjectButton').innerHTML = 'Failed';
    });
  };

  onCreateProject = (project) => {
    try {
      this.props.onCreateProject(project);
    } catch (error) {};
  };

  render() {

    let { projectName, projectDescription } = this.state;

    return (
      <div>

        <button type="button" className="col animated fadeIn btn btn-primary" data-toggle="modal" data-target="#createProjectModal" onClick={this.autofocusInput}>
        Create Project
        </button>

        <div className="modal text-dark fade" id="createProjectModal" style={{backgroundColor:'rgba(0, 0, 0, 0.5)'}} data-backdrop="false" tabIndex="-1" role="dialog" aria-labelledby="createProjectModalTitle" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="createProjectModalTitle">{"Create a new project"}</h5>
                <button id="closeCreateProjectModalButton" type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
              <form id="createProjectForm" onSubmit={this.onSubmit}>
                <div className="form-group">
                  <input className="form-control" id="projectName" type="text" name="projectName" placeholder="Name" value={projectName} onChange={this.onChange} autoFocus={true}/>
                </div>
                <div className="form-group">
                  <input className="form-control" id="projectDescription" type="text" name="projectDescription" placeholder="Description (optional)" value={projectDescription} onChange={this.onChange}/>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancel</button>
                  <button id="createProjectButton" type="submit" className="btn btn-primary">Create</button>
                </div>
              </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default CreateProject;
