// required libraries
import React, { Component } from 'react';
import axios from 'axios';
import io from 'socket.io-client';

// required components
import ClapIcon from './clap.svg';

// helper functions
const UTIL = require('../UtilityFunction/UtilityFunction');

// global variables controlling certain action status
let isEditing = false;
let isSearching = false;
let commentsDidContainGlobal = false;

// an array that holds the raw comments fetched from the server
let parentCommentsBuffer = [];

// an array that holds any comment that is a child comment
let childCommentsBuffer = [];

// we apply any filter to this array for final render
let searchBuffer = [];

class CommentsTable extends Component {

  constructor(props) {
    super(props);
    this.state = {
      newComment: undefined,
      serverResponse: undefined,
      updateContent: false,
    };

    // Connect to Socket.io server, subscribe to updates for this media
    this.socket = io();
    this.socket.emit('subscribeMedia', [this.props.currentMedia.id]);

    // On NEW_COMMENT event
    this.socket.on('NEW_COMMENT', function(data) {
      this.setState({newComment: data});
    }.bind(this));

    // On UPDATE_COMMENT event
    this.socket.on('UPDATE_COMMENT', function(data) {

      // update the DOM to
      try {
        document.getElementById('commentBody' + data.id).innerHTML = data.body;
      } catch (error) {}

      // update parentCommentsBuffer
      parentCommentsBuffer = parentCommentsBuffer.map((eachParent) => {
        if (eachParent.child !== undefined) {
          eachParent.child = eachParent.child.map((eachChild) => {
            if (eachChild.id === data.id) {
              eachChild.body = data.body;
            };
            return eachChild;
          });
          if (eachParent.id === data.id) {
            eachParent.upVote = data.upVote;
            eachParent.isGlobal = data.isGlobal;
            eachParent.isComplete = data.isComplete;
          };
          return eachParent;
        } else {
        if (eachParent.id === data.id) {
            eachParent.body = data.body;
            eachParent.upVote = data.upVote;
            eachParent.isGlobal = data.isGlobal;
            eachParent.isComplete = data.isComplete;
          };
          return eachParent;
          // eslint-disable-next-line
        };
      });

      try {
        document.getElementById('commentField').focus();
        document.getElementById('commentButton').className='animated bounceIn btn btn-primary';
        document.getElementById('commentButton').innerHTML='Updated!';
      } catch (error) {}

      // re-render the newly updated searchBuffer also make sure we clear the previous comment
      this.setState({newComment: undefined});

      setTimeout(function() {
        try {
          document.getElementById('commentButton').className='btn btn-outline-light';
          document.getElementById('commentButton').innerHTML='Comment';
        } catch (error) {}
      }, 1500);

      isEditing = false;

    }.bind(this));

    // On UPDATE_REPLY event
    this.socket.on('UPDATE_REPLY', function(data) {

      parentCommentsBuffer = parentCommentsBuffer.map((each) => {
        if (each.id === data.parentId) {
          let tempArray = [];
          if (each.child === undefined) {
            tempArray.push(data);
            each.child = tempArray;
          } else {
            tempArray = each.child;
            tempArray.push(data);
            each.child = UTIL.sortByKey(tempArray, 'date', 'ascend');
          };
        };
        return each;
      });

      try {
        document.getElementById('replyComment' + data.parentId).className='collapse';
        document.getElementById('repliedContent' + data.parentId).className='text-white collapse show';
        document.getElementById('replyCommentField' + data.parentId).value='';
      } catch (error) {};

      this.setState({newComment: undefined});
    }.bind(this));

    // On DELETE_COMMENT event
    this.socket.on('DELETE_COMMENT', function(data) {

      try {
        let targetRow = document.getElementById('commentRow' + data.id);
        targetRow.className = 'animated fadeOut bg-warning text-dark row';
      } catch (error) {
        console.log(error);
      };

      setTimeout(function() {

        // materialize the deletion on parentCommentsBuffer
        parentCommentsBuffer = parentCommentsBuffer.map((each) => {
          if (each.child !== undefined) {
            each.child = each.child.filter((each) => {
              return each.id !== data.id;
            });
            if (each.child.length === 0) {
              each.child = undefined;
            };
            return each;
          } else {
            return each;
            // eslint-disable-next-line
          };
        });

        // remove the comment from the array
        parentCommentsBuffer = parentCommentsBuffer.filter((each) => {
          return each.id !== data.id;
        });

        this.setState({newComment: undefined});

      }.bind(this), 1000);

    }.bind(this));
  };

  componentDidMount() {

    setTimeout(function() {
      this.fetchComments();
    }.bind(this), 100);

  };

  componentWillUnmount() {
    this.socket.disconnect()
  }

  fetchComments = () => {
    axios.get('/api/comment/media/' + this.props.currentMedia.id)
    .then((response) => {
      if (response.data.length !== 0) {
        try {
          // data processing, what we're doing here is to re-create a proper data
          // structure for later rendering

          // we filter out all the parent comments and child comments and then we
          // will attach all cooresponding child comments to its parent, so that
          // we will be able to render all the reply for a single comment properly
          parentCommentsBuffer = response.data.filter((each) => {
            return each.parentId === 'false';
          });

          childCommentsBuffer = response.data.filter((each) => {
            return each.parentId !== 'false';
          });

          parentCommentsBuffer = parentCommentsBuffer.map((eachParent) => {
            let tempArray = childCommentsBuffer.filter((eachChild) => {
              return (eachChild.parentId === eachParent.id);
            });
            if (tempArray.length !== 0) {
              eachParent.child = UTIL.sortByKey(tempArray, 'date', 'ascend');
              return eachParent;
            }
            return eachParent;
          });
        } catch (error) {
          console.log(error);
        }
      };
      this.setState({updateContent: true});
    }, (error) => {
      console.log(error);
    });
  };

  componentWillReceiveProps({searchString, currentImageTitle}) {
    this.filterComment(searchString);
    this.setState({currentImageTitle: currentImageTitle});
  };

  triggerReply = (event) => {
    document.getElementById('replyCommentField' + event.target.getAttribute('data-commentid')).focus();
  };

  submitReply = (event) => {

    let currentParentId = event.target.getAttribute('dataparentid');
    let replyCommentFieldId = ('replyCommentField' + currentParentId);

    if ((event.key === 'Enter' && event.target.value === '')|| event.key === 'Escape') {
      document.getElementById('replyComment' + currentParentId).className='collapse';
      return;
    };

    if (event.key !== 'Enter') {return};

    if (!UTIL.stringCheck(document.getElementById(replyCommentFieldId).value)) {return};

    // data preparation
    let replyToSend ={};

    if (this.props.currentMedia.type !== 'Album') {
      replyToSend = {
        media: this.props.currentMedia.id,
        body: document.getElementById(replyCommentFieldId).value,
        seconds: document.getElementById('timecodeOutput').value,
        parentId: currentParentId
      };
    };

    if (this.props.currentMedia.type === 'Album') {
      replyToSend = {
        media: this.props.currentMedia.id,
        imageId: document.getElementById('onSlideImageIdOutput').value,
        body: document.getElementById(replyCommentFieldId).value,
        seconds: document.getElementById('timecodeOutput').value,
        parentId: currentParentId
      };
    };

    // call api to post comment
    axios.post('/api/comment/media/' + this.props.currentMedia.id, replyToSend)
    .then((response) => {}, (error) => {
      console.log(error);
    });
  };

  editComment = (event) => {

    if (event.target.getAttribute('data-commentownerid') !== this.props.currentUser.id) {return};

    if (!isEditing) {

      let commentBeforeEdit = event.target.innerText;

      // replace with new html for editing comment
      event.target.innerHTML = '<span><textarea type="text" class="form-control text-white" style="width:100%; background-color: #1E2124;" id="editCommentField" placeholder="Editing comment..."/></span>';
      document.getElementById('editCommentField').setAttribute('data-commentid', event.target.getAttribute('data-commentid'));
      document.getElementById('editCommentField').setAttribute('data-commentBeforeEdit', commentBeforeEdit);
      document.getElementById('editCommentField').addEventListener('keydown', this.updateComment);
      document.getElementById('editCommentField').value = commentBeforeEdit;
      document.getElementById('editCommentField').focus();

      // prevent editing multiple comments at the same time
      isEditing = event.target.id;
    };
  };

  upVoteComment = (event) => {

    axios.patch('/api/comment/' + event.target.getAttribute('data-commentid'), {action: 'upVoteComment'})
    .then((response) => {}, (error) => {
      console.log(error);
    });

  };

  updateComment = (event) => {

    if (event.key === 'Enter' && event.target.value !== '') {

      let newCommentBody = event.target.value;

      if (!UTIL.stringCheck(newCommentBody)) {return};

      event.target.setAttribute('disabled', 'true');

      axios.patch('/api/comment/' + event.target.getAttribute('data-commentid'), {body: newCommentBody})
      .then((response) => {}, (error) => {
        console.log(error);
      });
      return;
    };

    if ((event.key === 'Enter' && event.target.value === '')|| event.key === 'Escape') {
      let restorecommentBeforeEdit = event.target.getAttribute('data-commentBeforeEdit')
      document.getElementById('commentBody' + event.target.getAttribute('data-commentid')).innerText = restorecommentBeforeEdit;
      isEditing = false;
      return;
    };
  };

  deleteComment = (event) => {
    event.preventDefault();

    let targetButton = document.getElementById(event.target.id);

    axios.delete('/api/comment/' + event.target.id)
    .then((response) => {
      if (response.status !== 200) {
        targetButton.className = 'btn btn-danger';
        targetButton.innerHTML = 'Error';
        this.setState({newComment: undefined});
        return;
      };
    }, (error) => {
      console.log(error);
    });
  };

  toggleGlobalComment = (event) => {
    event.preventDefault();

    let currentComment = event.target.getAttribute('data-commentid');
    let currentCommentStatus = event.target.getAttribute('data-commentisglobal');
    let newCommentStatus;

    if (currentCommentStatus === 'true') {
      newCommentStatus = false;
    } else {
      newCommentStatus = true;
    };

    axios.patch('/api/comment/' + currentComment, {isGlobal: newCommentStatus})
    .then((response) => {}, (error) => {
      this.setState({updateContent: true});
    });
  };

  toggleCompleteComment = (event) => {
    event.preventDefault();

    let currentComment = event.target.getAttribute('data-commentid');
    let currentCommentStatus = event.target.getAttribute('data-commentiscomplete');
    let newCommentStatus;

    if (currentCommentStatus === 'true') {
      newCommentStatus = false;
    } else {
      newCommentStatus = true;
    }

    axios.patch('/api/comment/' + currentComment, {isComplete: newCommentStatus, action:'reviewComment'})
    .then((response) => {}, (error) => {
      // this.setState({serverResponse: error.response});
    });
  }

  handleJumpTo = (event) => {
    let seconds = event.target.getAttribute('dataseconds');
    this.props.onJumpToSeconds(seconds);
  }

  filterComment = (searchString) => {

    if (searchString === undefined || searchString === '') {
      isSearching = false;
      searchBuffer = parentCommentsBuffer.slice(0);
      return;
    };

    searchBuffer = parentCommentsBuffer.slice(0);

    parentCommentsBuffer.map((each) => {
      if (each.child !== undefined) {
        each.child.map((each) => {
          searchBuffer.push(each);
          return each;
        });
      };
      return each;
    });

    searchBuffer = searchBuffer.filter((each) => {
      return (each.body.toLowerCase()).includes(searchString.toLowerCase());
    });

    this.setState({newComment: undefined});
    isSearching = true;
  };

  render() {

    if (this.state.newComment !== undefined) {
      parentCommentsBuffer.push(this.state.newComment);
      searchBuffer = parentCommentsBuffer.slice(0);
      this.setState({newComment: undefined});
    };

    if (!isSearching) {
      searchBuffer = parentCommentsBuffer.slice(0);
      UTIL.sortByKey(searchBuffer, 'seconds', 'ascend');
      commentsDidContainGlobal = false;
      searchBuffer.map((each) => {
        if (each.isGlobal === true) {
          commentsDidContainGlobal = true;
        };
        return each;
      });
      if (commentsDidContainGlobal) {
        UTIL.sortByKey(searchBuffer, 'isGlobal', 'descend');
      };
    };

    // when we are in photo commenting mode, we automatically filter comment by
    // the current slide (only display comments for the photo currently being displayed)
    if (this.props.currentMedia !== undefined && this.props.currentMedia.type === 'Album') {
      searchBuffer = searchBuffer.filter((each) => {
        // add new property when filtering
        each.imageTitle = this.props.currentImageTitle;
        return each.imageId === this.props.currentImageId;
      });
    };

    return (
      <div>

        <table className="animated fadeIn table table-striped table-hover">
          <thead className="thead-dark">
            <tr className="row">
              <th className="col-md-1" scope="col">Timecode</th>
              <th className="col-md-6 text-left" scope="col">Comment</th>
              <th className="col-md-1" scope="col">Claps</th>
              <th className="col-md-2" scope="col">Action</th>
              <th className="col-md-2" scope="col">Posted by</th>
            </tr>
          </thead>
        </table>

        {this.state.updateContent &&

          <div id="commentTableScroll" style={{display: "block", maxHeight:'800px', overflowY: 'auto', overflowX: 'hidden'}}>
            <table className="table table-striped table-hover">

              {(searchBuffer.length === 0) &&
                <div>{"No comment to display"}</div>
              }

              <tbody>
                {searchBuffer.map((each) => (
                  <div key={"parentCommentId" + each.id}>
                    <tr className={"animated fadeIn row " + (each.isComplete ? "bg-dark text-secondary" : "text-white")} id={"commentRow" + each.id} key={each.id}>

                      <th className="col-md-1" scope="row">
                      {(!each.isGlobal
                        ? <span id={'commentAtSecond' + UTIL.convertToTimecode(each.seconds)} data-commentid={each.id} style={{cursor: "pointer"}} dataseconds={each.seconds} onClick={this.handleJumpTo}>{UTIL.convertToTimecode(each.seconds)}</span>
                        : <span className="fas fa-thumbtack"></span>
                      )}
                      </th>

                      <td className="text-left col-md-6">
                        <div className="row container-fluid">
                        <div>
                        <span>
                          <i className={(each.isComplete ? "far fa-check-square" : "far fa-square")} style={{cursor: "pointer"}} data-commentid={each.id} data-commentiscomplete={String(each.isComplete)} onClick={this.toggleCompleteComment}/>
                        </span>
                        </div>
                        <div>
                        {each.child !== undefined &&
                          <span>
                            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                            <i className="fas fa-list-ol" style={{cursor: "pointer"}} data-toggle="collapse" data-target={"#repliedContent" + each.id}/>
                          </span>
                        }
                        </div>
                        <div className="col">
                        <span id={"commentBody" + each.id} data-commentid={each.id} data-commentownerid={each.owner.id} onClick={this.editComment} style={{textDecoration: (each.isComplete ? "line-through" : ""), cursor: "pointer"}} data-toggle="tooltip" title="Click to edit">{each.body}</span>
                        </div>
                        </div>
                      </td>

                      <td className="col-md-1">
                        <img src={ClapIcon} className="animated fadeIn hvr-pulse-grow" id={'upVoteComment' + each.id} alt="clap-icon" style={{cursor: "pointer"}} height="25px" data-commentid={each.id} datacurrentupvote={each.upVote} onClick={this.upVoteComment}/>
                        <span>&nbsp;</span>
                        <span>{each.upVote === undefined ? 0 : each.upVote}</span>
                      </td>

                      <td className="col-md-2">
                        <i className="far fa-comment-dots text-white" id={"replyCommentToggle" + each.id} style={{cursor: "pointer"}} data-commentid={each.id} data-toggle="collapse" data-target={"#replyComment" + each.id} onClick={this.triggerReply}/>
                        {each.owner.id === this.props.currentUser.id &&
                          <span>
                            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                            <i className={"fas fa-thumbtack " + (each.isGlobal ? "text-warning" : "")} id={"globalCommentToggle" + each.id} style={{cursor: "pointer"}} data-commentid={each.id} data-commentisglobal={String(each.isGlobal)} onClick={this.toggleGlobalComment}></i>
                            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                            <i className="fas fa-trash text-danger" id={each.id} style={{cursor: "pointer"}} dataisparent="true" onClick={this.deleteComment}></i>
                          </span>
                        }
                      </td>

                       <td className="col-md-2 text-nowrap">{each.owner.userName}</td>
                     </tr>

                     <div className="collapse" id={"replyComment"+ each.id}>
                       <tr>
                         <td style={{width:'10%'}}>{">>>>>>>>>>>>>>>>>>>>>"}</td>
                         <td className="text-right" style={{width:'80%'}}>
                           <input className="form-control" id={"replyCommentField" + each.id} type="text" name="reply" dataparentid={each.id} placeholder="Reply comment here..." onKeyDown={this.submitReply}/>
                         </td>
                         <td style={{width:'10%'}}>{"<<<<<<<<<<<<<<<<<<<<<"}</td>
                       </tr>
                     </div>

                     <div className="collapse text-white" id={"repliedContent" + each.id}>
                       {(each.child !== undefined && each.child.length > 0) &&
                         <div>
                           {each.child.map((each) => (
                             <tr className="animated fadeIn bg-primary row" id={"commentRow" + each.id} key={each.id}>
                               <th className="col-md-2 text-right" scope="row">{">>>>>>>>>>>>>>>>>>"}</th>
                               <td className="col-md-6 text-left" id={"commentBody" + each.id} style={{width:'60%'}} data-commentid={each.id} data-commentownerid={each.owner.id} onClick={this.editComment}>{each.body}</td>
                               <td className="col-md-2">{each.owner.id === this.props.currentUser.id &&
                                 <i className="fas fa-trash text-white" id={each.id} style={{cursor: "pointer"}} dataisparent="false" onClick={this.deleteComment}/>}
                               </td>
                               <td className="col-md-2 text-nowrap">{each.owner.userName}</td>
                             </tr>
                           ))}
                         </div>
                       }
                     </div>
                   </div>
                 ))}
               </tbody>
            </table>
          </div>
        }

        {!this.state.updateContent &&
          <div className="spinner">
            <div className="double-bounce1"></div>
            <div className="double-bounce2"></div>
          </div>
        }

      </div>
    );
  };
};

export default CommentsTable;
