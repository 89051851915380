import React, { Component } from 'react';
import axios from 'axios';

class RemoveCollaborator extends Component {

  constructor(props) {
    super(props);
    this.state = {
      projectName: undefined,
      projectDescription: undefined
    };
  };

  removeCollaborator = (event) => {
    event.preventDefault();
    let currentProjectId = this.props.currentProject.id;
    let currentCollaboratorId = this.props.currentCollaborator.id;

    document.getElementById('removeCollaboratorButton' + this.props.currentCollaborator.id).setAttribute('disabled', 'true');
    document.getElementById('removeCollaboratorButton' + this.props.currentCollaborator.id).innerHTML = 'Removing...';

    axios.post('/api/project/collaboration/remove-collaborator/' + currentProjectId, {collaboratorToRemove: currentCollaboratorId})
    .then((result) => {
      if (result.status === 200) {
        window.location.reload();
      };
    }, (error) => {
      document.getElementById('removeCollaboratorButton' + this.props.currentCollaborator.id).removeAttribute('disabled');
      document.getElementById('removeCollaboratorButton' + this.props.currentCollaborator.id).className = 'btn btn-danger';
      document.getElementById('removeCollaboratorButton' + this.props.currentCollaborator.id).innerHTML = 'Failed';
    });
  };

  render() {

    return (
      <span>

        <span>
          <i className="fas fa-eject" style={{cursor: "pointer"}} data-toggle="modal" data-target="#removeCollaboratorModal"/>
        </span>

        <div className="modal text-dark fade" id="removeCollaboratorModal" data-backdrop="false" tabIndex="-1" role="dialog" aria-labelledby="removeCollaboratorModalTitle" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title" id="removeCollaboratorModalTitle">{"WARNING"}</h4>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
              <form id="removeCollaboratorForm" onSubmit={this.removeCollaborator}>
                <h5>Are you sure you want to remove {this.props.currentCollaborator.userName} from project {this.props.currentProject.name}?</h5>
                <div className="modal-footer">
                  <button id={"removeCollaboratorButton" + this.props.currentCollaborator.id} type="button" className="btn btn-danger" data-dismiss="modal" onClick={this.removeCollaborator}>REMOVE</button>
                  <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancel</button>
                </div>
              </form>
              </div>
            </div>
          </div>
        </div>
      </span>
    )
  }
}

export default RemoveCollaborator;
