import React, { Component } from 'react';
import axios from 'axios';
// eslint-disable-next-line

const UTIL = require('../UtilityFunction/UtilityFunction');

class SignUp extends Component {

  constructor(props) {
    super(props);
    this.state = {
      userName: undefined,
      email: undefined,
      password: undefined,
      isAuthorized: false,
      passwordValidation: false
    };
  };

  componentWillReceiveProps({isAuthorized}) {
    // if current user is already logged in, redirect back to homepage
    if (isAuthorized) {
      window.location.assign('/');
    };
  };

  onChange = (event) => {
    document.getElementById('serverResponseEmailStatus').innerHTML = '';
    this.setState({ [event.target.name]: event.target.value });
    if (event.target.name === "password") {
      if (UTIL.pwdComplexityCheck(event.target.value)) {
        this.setState({passwordValidation: true})
        this.forceUpdate()
      } else {
        this.setState({passwordValidation: false})
      }
    }
  };

  onSubmit = (event) => {
    event.preventDefault();

    let userNameField = document.getElementById('userNameField');
    let emailField = document.getElementById('emailField');
    let passwordField = document.getElementById('passwordField');

    if (userNameField.value === '') {
      userNameField.className += ' animated bounceIn'
      return;
    };

    if (emailField.value === '') {
      emailField.className += ' animated bounceIn'
      return;
    };
    
    if (!UTIL.pwdComplexityCheck(passwordField.value)) {
      passwordField.className += ' animated bounceIn'
      return;
    }

    axios.post('/signup', { userName: userNameField.value, email: emailField.value, password: passwordField.value })
    .then((result) => {
      if (result.data.isAuthorized) {

        // pass user data back to App.js
        this.props.onLoggedIn( result.data );

        // UX codes
        document.getElementById('signUpButton').className='animated bounceIn btn btn-success';
        document.getElementById('signUpButton').innerHTML='SUCCESS!';
        setTimeout(function() {
          document.getElementById('signUpForm').className = 'animated fadeOut container text-white'
        }, 500);

        // redirect
        setTimeout(function() {
          window.location.assign('/');
        }, 1500);

      };
    }, (error) => {
      console.log(error);
      document.getElementById('serverResponseEmailStatus').innerHTML = error.response.data.message;
    });
  }

  render() {

    if (this.props.isAuthorized) {
      return(<div></div>);
    };

    return (
      <div id="signUpForm" className="animated fadeIn container text-white">
        <h2>Sign Up</h2>
        <hr/>
        <form className="animated slideInUp" onSubmit={this.onSubmit}>
          <div className="form-group">
            <input className="form-control container-fluid w-50" id="userNameField" type="text" name="userName" value={this.state.userName} placeholder="Name" onChange={this.onChange} autoFocus='true'/>
          </div>
          <div className="form-group">
            <span className="text-warning" id="serverResponseEmailStatus"></span>
            <input className="form-control container-fluid w-50" id="emailField" type="email" name="email" value={this.state.email} placeholder="Email Address" onChange={this.onChange}/>
          </div>
          <div className="form-group">
            {!this.state.passwordValidation &&
            <span className="text-warning">at least 8 characters long and 1 letter from [a-z], [A-z], [0-9]</span>
            }
            <input className="form-control container-fluid w-50" id="passwordField" type="password" name="password" value={this.state.password} placeholder="Password" onChange={this.onChange} data-toggle="tooltip" data-placement="right" title="Tooltip on right"/>
          </div>
          <button id="signUpButton" className=" btn btn-outline-light" type="submit" >Signup</button>
        </form>
        <hr/>
        <p>Already have an account? <a className="text-muted" href="/login">Login</a></p>
        <p>Or go <a className="text-muted" href="/">home</a>.</p>
      </div>
    );
  }
}

export default SignUp;
