import React, { Component } from 'react';
import axios from 'axios';
// eslint-disable-next-line
import { BrowserRouter as Router, Route, Link, Switch, Redirect } from 'react-router-dom';

class Login extends Component {

  constructor(props) {
    super(props);
    this.state = {
      email: undefined,
      password: undefined
    };
  };

  componentWillReceiveProps({isAuthorized}) {
    // if current user is already logged in, redirect back to homepage
    if (isAuthorized) {
      window.location.assign('/');
    };
  }

  onChange = (event) => {
    event.preventDefault();
    this.setState({[event.target.name]: event.target.value});
    document.getElementById('serverValidationStatus').innerHTML = '';
  };

  onSubmit = (event) => {
    event.preventDefault();

    let emailField = document.getElementById('emailField');
    let passwordField = document.getElementById('passwordField');

    if (emailField.value === '') {
      emailField.className += ' animated bounceIn'
      return;
    };

    if (passwordField.value === '') {
      passwordField.className += ' animated bounceIn'
      return;
    }

    document.getElementById('loginButton').setAttribute('disabled', 'true');
    document.getElementById('loginButton').innerHTML = 'Logging in...';

    axios.post('/login', { email: emailField.value, password: passwordField.value })
    .then((result) => {
      if (result.status === 200) {
        if (result.data.isAuthorized) {

          // pass user data back to App.js
          let next = window.location.href.split('?next=')[1];
          let host = window.location.host

          if (next !== undefined) {
            next = next.split(host)[1]
            this.props.onLoggedIn(result.data, true, next);
          } else {
            this.props.onLoggedIn(result.data, false, null);
          };

        };
      };
    }, (error) => {
      // UX codes
      document.getElementById('loginButton').className='animated bounceIn btn btn-danger';
      document.getElementById('loginButton').innerHTML='ERROR';

      setTimeout(function() {
        document.getElementById('loginButton').className='btn btn-outline-light';
        document.getElementById('loginButton').innerHTML='Login';
        document.getElementById('loginButton').removeAttribute('disabled');
      }, 2000);

      try {
        document.getElementById('serverValidationStatus').innerHTML=error.response.data.message;
      } catch (error) {};

    });
  };

  render() {

      if (this.props.isAuthorized) {
        return (<div></div>);
      };

      return (
        <div>
        <div id="loginForm" className="animated fadeIn container text-white">
          <h2>Welcome Back!</h2>
          <hr/>
          <form className="animated slideInUp" onSubmit={this.onSubmit}>
            <div className="form-group">
              <span className="text-warning" id="serverValidationStatus"></span>
              <input id="emailField" className="container-fluid w-50 form-control" type="email" name="email" value={this.state.email} placeholder="Email address" onChange={this.onChange} autofocus='true'/>
            </div>
            <div className="form-group">
              <input id="passwordField" className="container-fluid w-50 form-control" type="password" name="password" value={this.state.password} placeholder="Password" onChange={this.onChange}/>
            </div>
            <button id="loginButton" className="btn btn-outline-light" type="submit">Login</button>
          </form>
          <hr/>
          <p>Need an account? <a className="text-muted" href="/signup">Signup</a></p>
          <p>For got your password? <a className="text-muted" href="/password-reset">Reset Password Here.</a></p>
        </div>
      </div>
    );
  }
}

export default Login;
