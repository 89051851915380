import React, { Component } from 'react';
import axios from 'axios';

class LeaveProject extends Component {

  constructor(props) {
    super(props);
    this.state = {
      projectName: undefined,
      projectDescription: undefined
    };
  };

  leaveProject = (event) => {
    event.preventDefault();

    document.getElementById('leaveProjectButton' + this.props.currentProject.id).setAttribute('disabled', 'true');
    document.getElementById('leaveProjectButton' + this.props.currentProject.id).innerHTML = 'Processing...';

    axios.patch('/api/project/' + this.props.currentProject.id, {action: "leave"})
    .then((result) => {
      if (result.status === 200) {
        window.location.reload();
      };
    }, (error) => {
      document.getElementById('leaveProjectButton' + this.props.currentProject.id).removeAttribute('disabled');
      document.getElementById('leaveProjectButton' + this.props.currentProject.id).className = 'btn btn-danger';
      document.getElementById('leaveProjectButton' + this.props.currentProject.id).innerHTML = 'Failed';
    });
  };
  render() {

    return (
      <span>

        <span>
          <i className="fas fa-eject" style={{cursor: "pointer"}} data-toggle="modal" data-target="#leaveProjectModal"/>
        </span>

        <div className="modal text-dark fade" id="leaveProjectModal" style={{backgroundColor:'rgba(0, 0, 0, 0.5)'}} data-backdrop="false" tabIndex="-1" role="dialog" aria-labelledby="leaveProjectModalTitle" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title" id="leaveProjectModalTitle">{"WARNING"}</h4>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
              <form id="LeaveProjectForm" onSubmit={this.LeaveProject}>
                <h5>Are you sure you want to leave project {this.props.currentProject.name}?</h5>
                <div className="modal-footer">
                  <button id={"leaveProjectButton" + this.props.currentProject.id} type="button" className="btn btn-danger" onClick={this.leaveProject}>LEAVE</button>
                  <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancel</button>
                </div>
              </form>
              </div>
            </div>
          </div>
        </div>
      </span>
    )
  }
}

export default LeaveProject;
