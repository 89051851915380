import React, { Component } from 'react';
import axios from 'axios';

class EditMedia extends Component {

  constructor(props) {
    super(props);
    this.state = {
      mediaTitle: this.props.currentMedia.title,
      mediaDescription: this.props.currentMedia.description
    };
  };

  onChange = (event) => {
    event.preventDefault();
    this.setState({ [event.target.name]: event.target.value });
    document.getElementById('saveButton' + this.props.currentMedia.id).innerHTML = 'Save';
  };

  onSubmit = (event) => {
    event.preventDefault();

    let EditmediaTitleField = document.getElementById('editMediaTitle' + this.props.currentMedia.id);

    if (EditmediaTitleField.value === '') {
      EditmediaTitleField.className += ' animated bounceIn'
      return;
    };

    document.getElementById('saveButton' + this.props.currentMedia.id).setAttribute('disabled', 'true');
    document.getElementById('saveButton' + this.props.currentMedia.id).innerHTML = 'Saving...';

    let mediaToUpdate = {
      title: this.state.mediaTitle,
      description: this.state.mediaDescription
    };

    if (this.props.currentMedia.type !== 'Album') {
      axios.patch('/api/media/' + this.props.currentMedia.id, mediaToUpdate)
      .then((result) => {
        if (result.status === 200) {
          window.location.reload();
        };
      }, (error) => {
        console.log(error);
        document.getElementById('saveButton' + this.props.currentMedia.id).removeAttribute('disabled');
        document.getElementById('saveButton' + this.props.currentMedia.id).innerHTML = 'Failed';
        document.getElementById('saveButton' + this.props.currentMedia.id).className = 'btn btn-danger';
      });
    };

    if (this.props.currentMedia.type === 'Album') {
      axios.patch('/api/album/' + this.props.currentMedia.id, mediaToUpdate)
      .then((result) => {
        if (result.status === 200) {
          window.location.reload();
        };
      }, (error) => {
        console.log(error);
        document.getElementById('saveButton' + this.props.currentMedia.id).removeAttribute('disabled');
        document.getElementById('saveButton' + this.props.currentMedia.id).innerHTML = 'Failed';
        document.getElementById('saveButton' + this.props.currentMedia.id).className = 'btn btn-danger';
      });
    };
  };

  render() {

    let { mediaTitle, mediaDescription } = this.state;

    return (
      <span>

        <span>
          <i className="far fa-edit" style={{cursor: "pointer"}} data-toggle="modal" data-target={"#editMediaModal" + this.props.currentMedia.id}/>
        </span>

        <div className="modal text-dark fade" id={"editMediaModal" + this.props.currentMedia.id} style={{backgroundColor:'rgba(0, 0, 0, 0.5)'}} data-backdrop="false" tabIndex="-1" role="dialog" aria-labelledby="editMediaModalTitle" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="editMediaModalTitle">Edit Media</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
              <form id={"editMediaForm" + this.props.currentMedia.id} onSubmit={this.onSubmit}>
                <div className="form-group">
                  <input className="form-control" id={"editMediaTitle" + this.props.currentMedia.id} type="text" name="mediaTitle" placeholder={"Name"} value={mediaTitle} onChange={this.onChange}/>
                </div>
                <div className="form-group">
                  <input className="form-control" type="text" name="mediaDescription" placeholder="Description (optional)" value={mediaDescription} onChange={this.onChange}/>
                </div>
                <div className="modal-footer">
                  <button id={"saveButton" + this.props.currentMedia.id} type="submit" className="btn btn-primary">Save</button>
                  <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancel</button>
                </div>
              </form>
              </div>
            </div>
          </div>
        </div>
      </span>
    )
  }
}

export default EditMedia;
