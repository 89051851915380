import React, { Component } from 'react';
import axios from 'axios';

let projectsBuffer = [];

class AddMediaToProject extends Component {

  constructor(props) {
    super(props);
    this.state = {};
  };

  componentDidMount() {
    setTimeout(function() {
      this.fetchProjects();
    }.bind(this), 100);
  }

  fetchProjects = () => {
    axios.get('/api/project')
    .then((result) => {
      if (result.data !== undefined) {
        projectsBuffer = result.data.map((each) => {
          each.isCollaborating = false;
          if ((each.mediaArray !== undefined) && JSON.stringify(each.mediaArray).includes(this.props.currentMedia.id)) {
            each.isAddedToList = true;
          };
          return each;
        });
        this.setState({updateContent: true});
        this.fetchCollaboratingProjects();
      };
    }, (error) => {
      this.setState({serverResponse: error.response});
    });
  };

  fetchCollaboratingProjects = () => {
    axios.get('/api/project/collaboration/' + this.props.currentUser.id)
    .then((result) => {
      if (result.data !== undefined) {
        result.data.map((each) => {
          each.isCollaborating = true;
          if ((each.mediaArray !== undefined) && JSON.stringify(each.mediaArray).includes(this.props.currentMedia.id)) {
            each.isAddedToList = true;
          };
          projectsBuffer.push(each);
          return each;
        });
        this.setState({updateContent: true});
      };
    }, (error) => {
      this.setState({serverResponse: error.response});
    });
  };

  addToProject = (projectId, mediaId) => {

    // find the project we want to update from projectsBuffer using projectId, the return value is an array
    let projectToUpdate = projectsBuffer.filter((each) => {
      return each.id === projectId;
    });

    // retrieveing the object
    projectToUpdate = projectToUpdate[0];

    let mediaObject = {};

    if (this.props.currentMedia.type !== 'Album') {
      mediaObject.type = 'media';
      mediaObject.id = mediaId;
    };

    if (this.props.currentMedia.type === 'Album') {
      mediaObject.type = 'album';
      mediaObject.id = mediaId;
    };

    if (projectToUpdate.mediaArray === undefined) {
      projectToUpdate.mediaArray = [];
    };

    projectToUpdate.mediaArray.push(mediaObject);

    // we populated owners when we fetching projects,
    // but for updating a project, the project's owner field only accept the owner id value
    projectToUpdate.owner = projectToUpdate.owner.id;

    document.getElementById('addButton' + this.props.currentMedia.id).setAttribute('disabled', 'true');
    document.getElementById('addButton' + this.props.currentMedia.id).innerHTML = 'Processing...';

    // call API to update project
    axios.patch('/api/project/' + projectId, projectToUpdate)
    .then((result) => {
      if (result.status === 200) {
        window.location.reload();
      };
    }, (error) => {
      document.getElementById('addButton' + this.props.currentMedia.id).innerHTML = 'Failed';
      document.getElementById('addButton' + this.props.currentMedia.id).className = 'btn btn-danger';
      document.getElementById('addButton' + this.props.currentMedia.id).removeAttribute('disabled');
    });
  };

  onChange = (event) => {
    event.preventDefault();
    this.setState({ [event.target.name]: event.target.value });
  };

  onSubmit = (event) => {
    event.preventDefault();
    let addToProjectId = document.getElementById('projectSelect' + this.props.currentMedia.id).value;
    if (addToProjectId !== "undefined") {
      this.addToProject(addToProjectId, this.props.currentMedia.id);
      return;
    };
  };

  render() {

    return (
      <span>

        <span>
          <i className="fas fa-plus" style={{cursor: "pointer"}} data-toggle="modal" data-target={"#addMediaToProjectModal" + this.props.currentMedia.id} onClick={this.autofocusInput}/>
        </span>


        <div className="modal text-dark fade" id={"addMediaToProjectModal" + this.props.currentMedia.id} style={{backgroundColor:'rgba(0, 0, 0, 0.5)'}} data-backdrop="false" tabIndex="-1" role="dialog" aria-labelledby="addMediaToProjectModalTitle" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="addMediaToProjectModalTitle">Add Media to a Project</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
              <h5 className="text-left">{'\'' + this.props.currentMedia.title + '\' will be added to the following project:'}</h5>
              <form id={"addMediaToProjectForm" + this.props.currentMedia.id} onSubmit={this.onSubmit}>
                <div className="form-group">
                    <select className="custom-select" id={"projectSelect" + this.props.currentMedia.id}>
                      <option selected value="undefined">Choose...</option>
                      {projectsBuffer.map((each) => (
                        <option key={each.id} value={each.id} disabled={each.isAddedToList ? 'true' : ''}>{each.name}</option>
                      ))
                      }
                    </select>
                </div>
                <div className="modal-footer">
                  <button id={"addButton" + this.props.currentMedia.id} type="submit" className="btn btn-primary">Add</button>
                  <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancel</button>
                </div>
              </form>
              </div>
            </div>
          </div>
        </div>
      </span>
    )
  }
}

export default AddMediaToProject;
