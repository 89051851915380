import React, { Component } from 'react';
import axios from 'axios';
import YouTube from 'react-youtube';
import VimeoPlayer from '@vimeo/player';
import SC from '../../js/SoundCloudAPI'

// eslint-disable-next-line
import CreateProject from './Project/CreateProject';

let youtubePlayer;
let vimeoPlayer;
let currentVimeoVideo;

let soundcloudPlayer;
const SOUNDCLOUD_SRC = 'https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/'

let projectsBuffer = [];

class ImportMedia extends Component {

  constructor(props) {
    super(props);
    this.state = {
      sourceMedia: undefined,
      title: undefined,
      type: undefined,
      provider: undefined,
      mediaUrl: undefined,
      duration: undefined,
      description: undefined,
      isImporting: false
    };
  };

  componentDidMount() {

    setTimeout(function() {
      this.verifyDropboxAccess();
      this.fetchProjects();
    }.bind(this), 100);

    document.getElementById('importModuleButton').className = "animated fadeIn nav-item active";
  };

  componentWillUnmount() {
    document.getElementById('importModuleButton').className = "animated fadeIn nav-item";
  }

  verifyDropboxAccess = () => {

    axios.get('api/dropbox/authorization-status')
    .then((result) => {
      if (result.status === 200) {
        this.setState({dropboxAccess: true});
        return;
      };
      this.setState({dropboxAccess: false});
    });

  };

  triggerDropboxAuthorization = (event) => {
    window.location.assign('/auth/dropbox');
  };

  fetchProjects = () => {
    axios.get('/api/project')
    .then((result) => {
      if (result.data !== undefined) {
        projectsBuffer = result.data.map((each) => {
          each.isCollaborating = false;
          if ((each.mediaArray !== undefined) && each.mediaArray.includes(this.props.match.params.id)) {
            each.isAddedToList = true;
          }
          return each;
        });
        this.setState({updateContent: true});
        this.fetchCollaboratingProjects();
      }
    }, (error) => {
      this.setState({serverResponse: error.response});
    });
  }

  fetchCollaboratingProjects = () => {
    axios.get('/api/project/collaboration/' + this.props.currentUser.id)
    .then((result) => {
      if (result.data !== undefined) {
        result.data.map((each) => {
          each.isCollaborating = true;
          if ((each.mediaArray !== undefined) && each.mediaArray.includes(this.props.match.params.id)) {
            each.isAddedToList = true;
          };
          projectsBuffer.push(each);
          return each;
        });
        this.setState({updateContent: true});
      };
    }, (error) => {
      this.setState({serverResponse: error.response});
    });
  };

  addToProject = (projectId, mediaId) => {

    console.log(projectId)

    // find the project we want to update from projectsBuffer using projectId, the return value is an array
    let projectToUpdate = projectsBuffer.filter((each) => {
      return each.id === projectId;
    });

    // retrieveing the object
    projectToUpdate = projectToUpdate[0];

    let mediaObject = {};

    if (this.state.type !== 'Album') {
      mediaObject.type = 'media';
      mediaObject.id = mediaId;
    };

    if (this.state.type === 'Album') {
      mediaObject.type = 'album';
      mediaObject.id = mediaId;
    };

    projectToUpdate.mediaArray.push(mediaObject);

    // we populated owners when we fetching projects,
    // but for updating a project, the project's owner field only accept the owner id value
    projectToUpdate.owner = projectToUpdate.owner.id;

    // call API to update project
    axios.patch('/api/project/' + projectId, projectToUpdate)
    .then((result) => {
      if (result.status === 200) {
          // this.props.history.push('/media-library');
          return;
      }
    }, (error) => {
      document.getElementById('importButton').innerHTML = 'Failed';
      document.getElementById('importButton').className = 'btn btn-danger';
    });
  };

  detectDropboxMedia = (url) => {

    if (url.includes('dropbox.com')) {
      this.setState({ type: 'Album' });
      this.setState({ provider: 'dropbox' });
      return true;
    };
  };

  detectSoundCloudMedia = (url) => {

    if (url.includes('soundcloud.com')) {
      let options = {
        auto_play: true,
        show_artwork: true,
        show_comments: false,
        buying: false,
        sharing: false,
        enable_api: true,
        hide_related: true,
        visual: true
      };

      this.setState({ type: 'Sound' });
      this.setState({ provider: 'SoundCloud' });
      setTimeout(function() {
        try {
          soundcloudPlayer = SC.Widget(document.getElementById('soundcloudPlayerWindow'));
          soundcloudPlayer.load(url, options);
        } catch (error) {console.log(error)}
      }, 1000);
      return true;
    };
    return false;
  };

  detectYouTubeMedia = (url) => {

    // eslint-disable-next-line
    let regExp = /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:shorts\/)|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/ ]{11})/i;

    let match = url.match(regExp);
    // console.log(match);
    if ( match && match[1].length !== 0 ) {
      // console.log('matched id: ' + match[1]);
      return match[1];
    }
  };

  onYouTubePlayerReady = (event) => {
    // access to player in all event handlers via event.target
    youtubePlayer = event.target;
    youtubePlayer.mute(); // mute playback for import
    this.setState({playerReady: true});
  }

  detectVimeoMedia = (url) => {

    axios.get('https://vimeo.com/api/oembed.json?url=' + url).then(function(result) {

      currentVimeoVideo = result.data;

      this.setState({ type: 'Video'});
      this.setState({ provider: 'Vimeo'});
      this.setState({ sourceMedia: result.data.video_id});
      this.setState({ title: result.data.title });
      this.setState({ mediaUrl: 'vimeo.com/' + result.data.video_id });
      this.setState({ duration: result.data.duration});
      this.setState({ description: result.data.description});

      let options = {
        id: result.data.video_id,
        height: 540,
        width: '',
        transparent: 0,
        autoplay: true,
        loop: true
      };

      vimeoPlayer = new VimeoPlayer('vimeoPlayerWindow', options);
      vimeoPlayer.setVolume(0);

      document.getElementById('mediaPreviewModule').removeAttribute('hidden');
      document.getElementById('mediaPreviewModule').className='animated fadeIn jumbotron bg-secondary';
      document.getElementById('importButton').removeAttribute('disabled');

      return true;

    }.bind(this));
  };

  fillInfo = (event) => {

    if (this.state.provider === 'SoundCloud') {
      soundcloudPlayer.play();
      soundcloudPlayer.getCurrentSound(function(sound) {
        try {
          this.setState({ sourceMedia: sound.id});
          this.setState({ title: sound.title });
          this.setState({ mediaUrl: sound.permalink_url });
          this.setState({ duration: sound.full_duration/1000});
          this.setState({ description: sound.description});
        } catch (error) {
          if (error) {
            this.setState({abortImport: true});
            document.getElementById('importButton').setAttribute('disabled', 'true');
          }
        }
      }.bind(this));
    };

    if (this.state.provider === 'YouTube') {
      try {
        this.setState({ title: youtubePlayer.getVideoData().title });
        this.setState({ mediaUrl: youtubePlayer.getVideoUrl() });
        this.setState({ duration: youtubePlayer.getDuration() });
      } catch (error) {
        console.log(error);
      };
    };

    if (this.state.provider === 'Vimeo') {
      this.setState({ sourceMedia: currentVimeoVideo.video_id});
      this.setState({ title: currentVimeoVideo.title });
      this.setState({ mediaUrl: 'vimeo.com/' + currentVimeoVideo.video_id });
      this.setState({ duration: currentVimeoVideo.duration});
      this.setState({ description: currentVimeoVideo.description});
    };
  };

  mediaInterpretor = (event) => {

    this.setState({ [event.target.name]: event.target.value });

    document.getElementById('importButton').innerHTML = 'Import';
    document.getElementById('importButton').className = 'btn btn-outline-light';

    let extractedYoutubeVideoId = this.detectYouTubeMedia(event.target.value);
    let extractedVimeoVideoId = this.detectVimeoMedia(event.target.value);
    let extractedSoundCloudSoundId = this.detectSoundCloudMedia(event.target.value);

    if (extractedYoutubeVideoId) {
        this.setState({ provider: 'YouTube'});
        this.setState({ type: 'Video'});
        this.setState({ sourceMedia: extractedYoutubeVideoId});
        document.getElementById('mediaPreviewModule').removeAttribute('hidden');
        document.getElementById('mediaPreviewModule').className='animated fadeIn jumbotron bg-secondary';
        document.getElementById('importButton').innerHTML = 'Validating URL...';
        setTimeout(function(){
          document.getElementById('importButton').innerHTML = 'Import';
          document.getElementById('importButton').removeAttribute('disabled');
          document.getElementById('autoFillButton').click();
        },2000)
        return;
      } else if (extractedVimeoVideoId) {
        // all actions have to be done in callback
        return;
      } else if (extractedSoundCloudSoundId) {
        document.getElementById('mediaPreviewModule').removeAttribute('hidden');
        document.getElementById('mediaPreviewModule').className='animated fadeIn jumbotron bg-secondary';
        setTimeout(function() {
          document.getElementById('autoFillButton').click();
        }, 2000);
        document.getElementById('importButton').removeAttribute('disabled');
        return;
      } else if (this.detectDropboxMedia(event.target.value)) {
        document.getElementById('mediaPreviewModule').removeAttribute('hidden');
        document.getElementById('mediaPreviewModule').className='animated fadeIn jumbotron bg-secondary';
        document.getElementById('importButton').removeAttribute('disabled');
        return;
      } else {
        this.setState({ provider: undefined });
        document.getElementById('mediaPreviewModule').className='animated fadeOut jumbotron bg-secondary';
        document.getElementById('importButton').setAttribute('disabled', 'true');
      };
      return;
  };

  onChange = (event) => {
    event.preventDefault();
    this.setState({ [event.target.name]: event.target.value });
  };

  // create project
  handleCreateNewProejct = (event) => {
    if (event.target.value === 'createProject') {
      document.getElementById('toggleCreateProject').click();
    };
  }

  handleDismissCreateNewProject = (event) => {
    document.getElementById('projectSelect').selectedIndex = 0;
  }

  createProject = (event) => {
    event.preventDefault();

    let projectNameField = document.getElementById('projectName');

    if (projectNameField.value === '') {
      projectNameField.className += ' animated bounceIn'
      return;
    };

    let projectToCreate = {
      name: this.state.projectName,
      description: this.state.projectDescription
    };

    document.getElementById('createProjectButton').setAttribute('disabled', 'true');
    document.getElementById('createProjectButton').innerHTML = 'Creating...';

    axios.post('/api/project/create', projectToCreate)
    .then((result) => {
      if (result.status === 200) {
        this.fetchProjects();
        document.getElementById('createProjectButton').removeAttribute('disabled');
        document.getElementById('createProjectButton').className = 'btn btn-primary';
        document.getElementById('createProjectButton').innerHTML = 'Create';
        document.getElementById('dismissCreateProject').click();
        this.setState({projectName: ''});
        this.setState({projectDescription: ''});
        setTimeout(function() {
          try {
            document.getElementById('project' + result.data.id).setAttribute('selected', 'true');
          } catch (error) {
            console.log(error);
          };
        }, 1000);
      }
    }, (error) => {
      console.log(error);
      document.getElementById('createProjectButton').removeAttribute('disabled');
      document.getElementById('createProjectButton').className = 'btn btn-danger';
      document.getElementById('createProjectButton').innerHTML = 'Failed';
    });
  }

  autoFocusInput = (event) => {
    setTimeout(function(){
      document.getElementById('projectName').focus();
    }, 500)
  }

 onSubmit = (event) => {
   event.preventDefault();

   this.setState({isImporting: true});
   document.getElementById('importButton').setAttribute('disabled', 'true');
   document.getElementById('importButton').innerHTML = 'Importing...';

   let mediaUrlField = document.getElementById('mediaUrl');
   let titleField = document.getElementById('title');

   if (mediaUrlField.value === '') {
     mediaUrlField.className += ' animated bounceIn'
     return;
   };

   if (titleField.value === '') {
     titleField.className += ' animated bounceIn'
     return;
   };

   let mediaInfo = this.state;

   if (this.state.type === 'Album') {

     axios.post('/api/dropbox', mediaInfo)
     .then((result) => {
       if (result.status === 200) {

         // we will also get back a media object as we will for calling
         // media api, we're receiving a delegate of the imported album
         let addToProjectId = document.getElementById('projectSelect').value;
         if (addToProjectId !== "undefined") {
           this.addToProject(addToProjectId, result.data.id);
           // return;
         };
         // this.props.history.push('/media-library');
         this.props.history.push('/media/' + result.data.id);
       };
     }, (error) => {
       console.log(error);
       this.setState({isImporting: false});
       document.getElementById('importButton').innerHTML = 'Failed';
       document.getElementById('importButton').className = 'btn btn-danger';
     });
     return;
   };

   axios.post('/api/media', mediaInfo)
   .then((result) => {
     if (result.status === 200) {
       let addToProjectId = document.getElementById('projectSelect').value;
       if (addToProjectId !== "undefined") {
         this.addToProject(addToProjectId, result.data.id);
         // return;
       };
       // this.props.history.push('/media-library');
       this.props.history.push('/media/' + result.data.id);
     };
   }, (error) => {
     console.log(error);
     this.setState({isImporting: false});
     document.getElementById('importButton').innerHTML = 'Failed';
     document.getElementById('importButton').className = 'btn btn-danger';
   });
 };

 reloadImportModule = (event) => {
   window.location.reload();
 }

 render() {

    // https://developers.google.com/youtube/player_parameters
    let opts = {
      height: 540,
      width: '100%',
      playerVars: {
        autoplay: 1,
        rel: 0,
        showinfo: 1,
        controls: 0,
        modestbranding: 1,
        loop: 1,
        playsinline: 1
      }
    };

    let { title, mediaUrl, description } = this.state;
    let { projectName, projectDescription } = this.state;

    return (
      <div className="animated fadeIn container-fluid text-white">

      <span hidden className="animated fadeIn btn btn-primary" id="toggleCreateProject" data-toggle="modal" data-target="#createProjectModal" onClick={this.autoFocusInput}></span>

      <div className="modal text-dark fade" id="createProjectModal" style={{backgroundColor:'rgba(0, 0, 0, 0.5)'}} data-backdrop="false" tabIndex="-1" role="dialog" aria-labelledby="createProjectModalTitle" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="createProjectModalTitle">{"Create a new project"}</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
            <form id="createProjectForm" onSubmit={this.createProject}>
              <div className="form-group">
                <input className="form-control" id="projectName" type="text" name="projectName" placeholder="Name" value={projectName} onChange={this.onChange} autoFocus={true}/>
              </div>
              <div className="form-group">
                <input className="form-control" id="projectDescription" type="text" name="projectDescription" placeholder="Description (optional)" value={projectDescription} onChange={this.onChange}/>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" id="dismissCreateProject" data-dismiss="modal" onClick={this.handleDismissCreateNewProject}>Cancel</button>
                <button id="createProjectButton" type="submit" className="btn btn-primary">Create</button>
              </div>
            </form>
            </div>
          </div>
        </div>
      </div>


        <div className="animated fadeIn container-fluid text-white" >
        <div className="row">

            <h3 className="col text-left text-white">Import Media</h3>

        </div>

        <div className="row">

          <div className="col-3">
            <div className="animated fadeInLeft jumbotron bg-secondary">
              <form onSubmit={this.onSubmit}>
                <div className="form-group">
                  <div className="text-left">Media URL</div>
                    <input id="mediaUrl" className="form-control" type="text" name="mediaUrl" value={mediaUrl} onChange={this.mediaInterpretor} autoFocus={true}/>
                  </div>
                <div className="form-group">
                  <div className="text-left">Title</div>
                    <input id="title" className="form-control" type="text" name="title" value={title} onChange={this.onChange}/>
                  </div>
                <div className="form-group">
                  <div className="text-left">Description</div>
                   <input id="description" className="form-control" type="text" name="description" value={description} onChange={this.onChange}/>
                  </div>
                <div className="form-group">
                  <div className="text-left">Add to a project</div>
                    <select className="custom-select" id="projectSelect" onChange={this.handleCreateNewProejct}>
                      <option selected value="undefined" disabled>Choose...</option>
                      {projectsBuffer.map((each) => (
                        <option key={each.id} id={'project' + each.id} value={each.id} {...each.isAddedToList ? 'disabled' : ''}>{each.name}</option>
                      ))}
                      <hr/>
                      <option value="createProject">Create a new project</option>
                    </select>
                </div>
                <br/>
                <button id="autoFillButton" className="btn btn-outline-light" onClick={this.fillInfo} type="button">Fill Info</button>
                <span>&nbsp;&nbsp;&nbsp;</span>
                <button className="btn btn-outline-light" id="importButton" type="submit" disabled>Import</button>
                <span>&nbsp;&nbsp;&nbsp;</span>
                <i className="fas fa-undo-alt" style={{cursor: "pointer"}} onClick={this.reloadImportModule}></i>
              </form>
            </div>
          </div>

          <div className="col-9">
            <div hidden className="animated fadeInLeft jumbotron bg-secondary" id='mediaPreviewModule' style={{height:'660px'}}>
            {this.state.provider === "YouTube" &&
              <YouTube className="animated fadeIn" videoId={this.state.sourceMedia} opts={opts} onReady={this.onYouTubePlayerReady}/>
            }
            {this.state.provider === "Vimeo" &&
              <div style={{backgroundColor: '#000000'}}>
              <div className="animated fadeIn" id="vimeoPlayerWindow" style={{width:"100%"}}></div>
              </div>
            }
            {this.state.provider === "SoundCloud" &&
            <div>
              <iframe className="animated fadeIn" id="soundcloudPlayerWindow" title="soundcloud player" width="100%" height='540px' scrolling="no" frameborder="no" src={SOUNDCLOUD_SRC}></iframe>
            </div>
            }
            {this.state.provider === "dropbox" &&
              <div className="animated fadeIn">
                <p className="fab fa-dropbox fa-5x"></p>
                <h5>It looks like you are importing photos from Dropbox!</h5>
                <h5>Heads up! Images will be copied and stored on our servers for 180 days, and they will be permanently removed afterward.</h5>
                {this.state.dropboxAccess
                  ? <button id="authDropboxButton" className="btn btn-success">Authorized</button>
                  : <button id="triggerDropboxAuthButton" className="btn btn-outline-light" onClick={this.triggerDropboxAuthorization} type="button">Request Dropbox Authorization</button>
                }
                {this.state.isImporting &&
                <div className="spinner">
                  <div className="double-bounce1"></div>
                  <div className="double-bounce2"></div>
                </div>
                }
              </div>
            }
            </div>
          </div>
        </div>
        </div>
      </div>
    )
  }
}

export default ImportMedia;
