import React, { Component } from 'react';
import axios from 'axios';

class UpdateAccount extends Component {

  constructor(props) {
    super(props);
    this.state = {
      email: '',
      userName: ''
    };
  };

  onChange = (event) => {
    event.preventDefault();
    this.setState({[event.target.name]: event.target.value});
    document.getElementById('newEmailDidFailValidation').innerHTML = '';
    document.getElementById('updateAccountButton').innerHTML = 'Update';
    document.getElementById('updateAccountButton').className = 'btn btn-primary';
    document.getElementById('updateAccountButton').removeAttribute('disabled')
    if (document.getElementById('newUserNameField').value === '' && document.getElementById('newEmailField').value === '') {
      document.getElementById('updateAccountButton').setAttribute('disabled', 'true')
    };
  }

  updateAccount = (event) => {
    event.preventDefault();

    document.getElementById('updateAccountButton').setAttribute('disabled', 'true');
    document.getElementById('updateAccountButton').innerHTML = 'Updating...';

    axios.patch('/api/user/updateAccount', {email: this.state.email, userName: this.state.userName})
    .then((result) => {
      if (result.status === 200) {
        window.location.reload();
      }
    }, (error) => {
      document.getElementById('newEmailDidFailValidation').innerHTML = error.response.data.message;
      document.getElementById('updateAccountButton').innerHTML = 'Failed';
      document.getElementById('updateAccountButton').className = 'btn btn-danger';
      document.getElementById('updateAccountButton').removeAttribute('disabled');
    })
  };


  render() {

    return (
      <span>

      <button type="button" className="btn btn-warning" data-toggle="modal" data-target="#updateAccountModal">
        Update
      </button>

        <div className="modal text-dark fade" id="updateAccountModal" style={{backgroundColor:'rgba(0, 0, 0, 0.8)'}} data-backdrop="false" tabIndex="-1" role="dialog" aria-labelledby="updateAccountModalTitle" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title" id="updateAccountModalTitle">Update profile</h4>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
              <form id="updateAccountForm" onSubmit={this.updateAccount}>
                <div className="form-group text-left">
                  <label>Current Login Email Address:</label>
                  <input className="form-control" id="currentEmailField" type="text" name="currentEmail" value={this.props.currentUser.email} disabled/>
                </div>
                <div className="form-group">
                  <span className="text-danger" id="newEmailDidFailValidation"></span>
                  <input className="form-control" id="newEmailField" type="email" name="email" placeholder="New login email" onChange={this.onChange} autoFocus={true}/>
                </div>
                <div className="form-group text-left">
                  <label>Current User Name:</label>
                  <input className="form-control" id="currentUserNameField" type="text" name="currentUserName" value={this.props.currentUser.userName} disabled/>
                </div>
                <div className="form-group">
                  <span className="text-danger" id="newUserNameDidFailValidation"></span>
                  <input className="form-control" id="newUserNameField" type="text" name="userName" placeholder="New user name" onChange={this.onChange} autoFocus={true}/>
                </div>
                <div className="modal-footer">
                  <button id="updateAccountButton" type="submit" className="btn btn-primary" disabled>Update</button>
                  <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancel</button>
                </div>
              </form>
              </div>
            </div>
          </div>
        </div>
      </span>
    )
  }
}

export default UpdateAccount;
