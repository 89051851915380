import React, { Component } from 'react';
import axios from 'axios';

class DeclineInvitation extends Component {

  constructor(props) {
    super(props);
    this.state = {};
  };

  declineInvitation = (event) => {
    event.preventDefault();
    let invitationId = event.target.getAttribute('data-invitationId');
    axios.patch('/api/invitation/' + invitationId, {action: 'decline'})
    .then((result) => {
      if (result.status === 200) {
        // in the future, prevent reloading
        window.location.reload();
      };
    }, (error) => {
      console.log(error);
    });
  };


  render() {

    return (
      <span>

        <span>
          <i className="fas fa-ban" style={{cursor: "pointer"}} data-toggle="modal" data-target={"#declineInvitationModal" + this.props.currentInvitation.id}></i>
        </span>

        <div className="modal text-dark fade" id={"declineInvitationModal" + this.props.currentInvitation.id} style={{backgroundColor:'rgba(0, 0, 0, 0.5)'}} data-backdrop="false" tabIndex="-1" role="dialog" aria-labelledby="DeclineInvitationModalTitle" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title" id="declineInvitationModalTitle">{"WARNING"}</h4>
                <button type="button" className="close" data-toggle="modal" data-target={"#declineInvitationModal" + this.props.currentInvitation.id} aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
              <form id="declineInvitationForm" onSubmit={this.DeclineInvitation}>
                <h5>Are you sure you want to decline {this.props.currentInvitation.inviter.userName + '\'s invitation?'}</h5>
                <div className="modal-footer">
                  <button id={'declineInvitationButton' + this.props.currentInvitation.id} type="submit" data-invitationId={this.props.currentInvitation.id} className="btn btn-danger" onClick={this.declineInvitation}>DECLINE</button>
                  <button type="button" className="btn btn-secondary" data-toggle="modal" data-target={"#declineInvitationModal" + this.props.currentInvitation.id}>Cancel</button>
                </div>
              </form>
              </div>
            </div>
          </div>
        </div>
      </span>
    )
  }
}

export default DeclineInvitation;
