// import core libraries
import React, { Component } from 'react';
import axios from 'axios';
// eslint-disable-next-line
import { BrowserRouter as Router, Route, Link, Switch, Redirect } from 'react-router-dom';
import CreateProject from './CreateProject';

let projectsBuffer = [];

// App starts here
class ProjectWidget extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showDropDown: false
    };
  };

  componentDidMount() {

    try {
      // we only show the dropdown menu when we're viewing a media
      if (this.props.match.path === '/media/:id') {
        this.setState({media: this.props.match.params.id});
        this.setState({type: 'Media'});
        this.setState({showDropDown: true});
      };
      if (this.props.match.path === '/album/:id') {
        this.setState({media: this.props.match.params.id});
        this.setState({type: 'Album'});
        this.setState({showDropDown: true});
      };
    } catch (error) {
      console.log(error);
    };
    this.fetchProjects();
  };

  fetchProjects = () => {
    axios.get('/api/project')
    .then((result) => {
      if (result.data !== undefined) {
        projectsBuffer = result.data.map((each) => {
          each.isCollaborating = false;
          if ((each.mediaArray !== undefined) && JSON.stringify(each.mediaArray).includes(this.props.match.params.id)) {
            each.isAddedToList = true;
          }
          return each;
        });
        this.setState({updateContent: true});
        this.fetchCollaboratingProjects();
      };
    }, (error) => {
      // this.setState({serverResponse: error.response});
    });
  };

  fetchCollaboratingProjects = () => {
    axios.get('/api/project/collaboration/' + this.props.currentUser.id)
    .then((result) => {
      if (result.data !== undefined) {
        result.data.map((each) => {
          each.isCollaborating = true;
          if ((each.mediaArray !== undefined) && JSON.stringify(each.mediaArray).includes(this.props.match.params.id)) {
            each.isAddedToList = true;
          };
          projectsBuffer.push(each);
          return each;
        });
        this.setState({updateContent: true});
      };
    }, (error) => {
      // this.setState({serverResponse: error.response});
    });
  };

  addToProject = (event) => {

    event.preventDefault();

    // save the reference of the target for use in callback function
    let eventTarget = event.target;

    // find the project we want to update from projectsBuffer using projectId, the return value is an array
    let projectToUpdate = projectsBuffer.filter((each) => {
      return each.id === event.target.id;
    });

    // retrieveing the object
    projectToUpdate = projectToUpdate[0];

    if (projectToUpdate.mediaArray === undefined) {
      projectToUpdate.mediaArray = [];
    };

    let mediaObject = {};

    if (this.state.type !== 'Album') {
      mediaObject.type = 'media';
      mediaObject.id = this.props.match.params.id;
    };

    if (this.state.type === 'Album') {
      mediaObject.type = 'album';
      mediaObject.id = this.props.match.params.id;
    };

    mediaObject.addedBy = this.state.userName;

    projectToUpdate.mediaArray.push(mediaObject);

    // we populated owners when we fetching projects,
    // but for updating a project, the project's owner field only accept the owner id value
    projectToUpdate.owner = projectToUpdate.owner.id;

    // call API to update project
    axios.patch('/api/project/' + event.target.id, projectToUpdate)
    .then((result) => {
      if (result.status === 200) {
        eventTarget.className='animated fadeIn fas fa-check';
      };
    }, (error) => {
      // this.setState({serverResponse: error.response});
    });
  };

  onHoverIn = (event) => {
    event.preventDefault();
    try {
      if (this.state.showDropDown) {
        document.getElementById('projectDropdownButton').className = 'nav-item dropdown show';
        document.getElementById('projectDropdownItemList').className = 'dropdown-menu show';
        document.getElementById('imageGalleryDisplayWrapper').style.position = 'relative';
        document.getElementById('imageGalleryDisplayWrapper').style.zIndex = -1;
        event.target.setAttribute('aria-expanded', true);
      };
    } catch(error) {};
    return;
  };

  onHoverOut = (event) => {
    event.preventDefault();
    try {
      if (this.state.showDropDown) {
        document.getElementById('projectDropdownItemList').className = 'dropdown-menu';
        document.getElementById('projectDropdownButton').className = 'nav-item dropdown';
        document.getElementById('navbarDropdown').setAttribute('aria-expanded', false);
        document.getElementById('imageGalleryDisplayWrapper').style.zIndex = 1;
      };
    } catch(error) {
      console.log(error);
    };
    return;
  };

  render() {

    return (
      <div>

      <li id="projectDropdownButton" className={this.state.showDropDown ? "nav-item dropdown active" : "nav-item dropdown"} style={{position: 'relative', zIndex: 100}}>
        <a className="nav-link dropdown-toggle" id="navbarDropdown" href="/project-library" role="button"  aria-haspopup="true" aria-expanded="false" onMouseOver={this.onHoverIn}>
          Project
        </a>
        {this.state.showDropDown &&
        <div id="projectDropdownItemList" className="dropdown-menu" aria-labelledby="navbarDropdown">
          <div className="container-fluid" onMouseOut={this.onHoverOut} onMouseOver={this.onHoverIn}>
          {projectsBuffer.map((each) => (
            <div className="dropdown-item" key={"projectDropdownItem" + each.id}>
            <div className="row" id={"projectWidgetRow" + each.id} style={{width:'300px'}} key={each.id}>
              <div className="col-10">
                <a className="dropdown-item text-truncate" href={"/project/" + each.id}>
                {each.isCollaborating &&
                  <span>
                  <i className="fab fa-slideshare"></i>
                  <span>{" "}</span>
                  </span>
                }
                {each.name}
                </a>
              </div>
              {!each.isAddedToList &&
                <div className="col-2">
                  <a className="fas fa-plus" id={each.id} href="/" onClick={this.addToProject}> </a>
                </div>
              }
              {each.isAddedToList &&
                <div className="col-2">
                  <i  className="fas fa-check" id={each.id} style={{cursor: "pointer"}}></i>
                </div>
              }
            </div>
            </div>
            ))}
            <div className="dropdown-divider"></div>
            <div className="dropdown-item d-flex justify-content-center">
              <CreateProject/>
            </div>
          </div>
        </div>
      }
      </li>
      </div>
    )
  }
}

export default ProjectWidget;
