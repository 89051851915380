// required libraries
import React, { Component } from 'react';
import axios from 'axios';
import io from 'socket.io-client';

// required components
import CommentsTable from './CommentsTable';
import MediaBrowser from './MediaBrowser';
import Sharing from '../Sharing/Sharing';

const UTIL = require('../UtilityFunction/UtilityFunction');

// Global variables
let keyTrack = {}; // track key(s) pressed
let activeUsersBuffer = [];
let isFilterButtonOn = false;
let isGlobalCommentButtonOn = false;

class CommentWindow extends Component {

  constructor(props) {
    super(props);
    this.state = {
      currentMedia: undefined,
      updateContent: false,
      mountMediaBrowser: false,
      mountCommentTable: false,
      controlCommand: undefined,
      searchString: undefined
    };

    // Establish connection to Socket.io server
    this.socket = io("/", {transports: ['websocket'], upgrade: false});

    if (this.props.currentUser.userName !== undefined) {
      this.socket.emit('subscribeMedia', [this.props.match.params.id, this.props.currentUser.userName]);
    };

    // On ACTIVE_USER event
    // Display current active user in this media room
    this.socket.on('ACTIVE_USER', function(data) {
      if (data !== null) {
        activeUsersBuffer = data.map(function(each){
          return each;
        });
        this.setState({updateContent: true});
      };
    }.bind(this));

    // On REMOVE_USER event
    // Remove current active user for this media room
    this.socket.on('REMOVE_USER', function(data) {
      activeUsersBuffer = activeUsersBuffer.filter((each) => {
        return each.socketId !== data;
      });
      document.getElementById('activeUser' + data).className='animated fadeOut btn btn-outline-light';
      setTimeout(function() {
        this.setState({updateContent: true});
      }.bind(this),1000);
    }.bind(this));

    this.handleJumpTo = this.handleJumpTo.bind(this);
    this.handleImageComment = this.handleImageComment.bind(this);
  };

  componentDidMount() {
    this.setState({currentUser: this.props.currentUser});
    setTimeout(function() {
      this.retrieveMedia();
    }.bind(this), 100);

    window.addEventListener('keydown', (event) => {
      this.shortCutTriggered(event);
    })

    window.addEventListener('keyup', (event) => {
      this.shortCutReleased(event);
    })

  };

  retrieveMedia = () => {

    if (this.props.match.path === '/media/:id') {
      axios.get('/api/media/' + this.props.match.params.id)
      .then((response) => {
        if (response.status === 200) {
          this.setState({currentMedia: response.data});
          this.setState({mountMediaBrowser: true});
          this.setState({mountCommentTable: true});
          this.setState({updateContent: true});
        };
      }, (error) => {
        console.log(error);
        window.location.assign('/media-library');
      });
    };

    if (this.props.match.path === '/album/:id') {
      axios.get('/api/album/' + this.props.match.params.id)
      .then((response) => {
        if (response.status === 200) {

          response.data.imageArray = UTIL.sortByKey(response.data.imageArray, 'title', 'ascend');

          this.setState({currentMedia: response.data});
          this.setState({mountMediaBrowser: true});
          this.setState({mountCommentTable: true});
          this.setState({updateContent: true});
          return;
        };
      }, (error) => {
        console.log(error);
      });
    };
  }

  onChange = (event) => {

    this.setState({controlCommand: 'shuttleStop'});
    document.getElementById('commentButton').className='btn btn-outline-light';
    document.getElementById('commentButton').innerHTML='Comment';

    if (event.target.value.slice(0,8) === 'filter: ') {
      this.setState({searchString: event.target.value.slice(8)});
      event.target.className='form-control bg-dark text-white'
      document.getElementById('filterButton').className='btn btn-warning';
      return;
    };

    if (event.target.value.slice(0,8) === 'global: ') {
      event.target.className='form-control bg-dark text-white';
      document.getElementById('globalCommentButton').className='btn btn-warning';
      return;
    };

    if (this.state.searchString === undefined) {
      return;
    } else {
      this.setState({searchString: undefined});
      event.target.className='form-control';
      document.getElementById('filterButton').className='btn btn-outline-light';
      document.getElementById('globalCommentButton').className='btn btn-outline-light';
    };
  };

  onSubmit = (event) => {

    event.preventDefault();

    let commentField = document.getElementById('commentField');
    let commentBody = undefined;
    let isGlobal = false;

    // resume playback if there is nothing to submit
    if (commentField.value === '' || commentField.value.slice(0,8) === 'filter: ' || (commentField.value.slice(0,8) === 'global: ' && commentField.value.slice(8) === '')) {
      this.resumePlayback();
    };

    if (commentField.value.slice(0,8) === 'global: ') {
      commentBody = commentField.value.slice(8);
      isGlobal = true;
    } else {
      commentBody = commentField.value;
    };

    // data preparation
    if (!UTIL.stringCheck(commentBody)) {return};

    let commentToSend;

    if (this.state.currentMedia.type !== 'Album') {
      commentToSend = {
        media: this.state.currentMedia.id,
        body: commentBody,
        seconds: document.getElementById('timecodeOutput').value,
        isGlobal: isGlobal
      };
    };

    if (this.state.currentMedia.type === 'Album') {
      commentToSend = {
        media: this.state.currentMedia.id,
        imageId: document.getElementById('onSlideImageIdOutput').value,
        body: commentBody,
        seconds: 0,
        isGlobal: isGlobal
      };
    };

    // call api to post comment
    axios.post('/api/comment/media/' + commentToSend.media, commentToSend)
    .then((response) => {
      if (response.status === 200) {
        this.setState({controlCommand: 'playVideo'});
        document.getElementById('timecodeDisplay').className = 'btn btn-outline-warning';
        document.getElementById('commentField').value = '';
        document.getElementById('commentButton').className = 'animated bounceIn btn btn-success';
        document.getElementById('commentButton').innerHTML = 'Commented!';
        document.getElementById('commentField').className = 'form-control';
        document.getElementById('globalCommentButton').className = 'btn btn-outline-light';
        setTimeout(function() {
          document.getElementById('commentButton').className = 'btn btn-outline-light';
          document.getElementById('commentButton').innerHTML = 'Comment';
        }, 1500);
      };
    }, (error) => {
      document.getElementById('commentButton').innerHTML = `Error (${error.response.status})`;
      document.getElementById('commentButton').className = 'animated bounceIn btn btn-danger';
      setTimeout(function() {
        document.getElementById('commentButton').className = 'btn btn-outline-light';
        document.getElementById('commentButton').innerHTML = 'Comment';
      }, 5000);
      console.log(error.response);
    });
  };

  resumePlayback = () => {
    let commentField = document.getElementById('commentField');
    this.setState({controlCommand:'playVideo'});
    this.setState({searchString: undefined});
    document.getElementById('timecodeDisplay').className='btn btn-outline-warning';
    document.getElementById('commentField').className='form-control'
    document.getElementById('filterButton').className='btn btn-outline-light'
    document.getElementById('globalCommentButton').className='btn btn-outline-light'
    commentField.value = '';
    return;
  };

  handleOnBlur = (event) => {
    // this.resumePlayback();
  }

  shortCutTriggered = (event) => {

    try {
      keyTrack[event.keyCode] = true;
    } catch (error) {
      console.log(error)
      return;
    }

    if (keyTrack[27]) {
      this.resumePlayback();
    };

    // CTRL + K + J: Simulating 1 frame backwards
    if (keyTrack[17] && keyTrack[75] && keyTrack[74]) {
      document.getElementById('commentField').focus()
      this.setState({controlCommand: 'stepBackward'});
      return;
    };
    // CTRL + K + L: Simulating 1 frame forwards
    if (keyTrack[17] && keyTrack[75] && keyTrack[76]) {
      document.getElementById('commentField').focus()
      this.setState({controlCommand: 'stepForward'});
      return;
    };
    // CTRL + K: Shuttle stop
    if (keyTrack[17] && keyTrack[75]) {
      document.getElementById('commentField').focus()
      this.setState({controlCommand: 'shuttleStop'});
      return;
    };
    // CTRL + L: Shuttle right, press repeatly will increase playback speed
    if (keyTrack[17] && keyTrack[76]) {
      document.getElementById('commentField').focus()
      this.setState({controlCommand: 'shuttleRight'});
      return;
    };
    // CTRL + J: Shuttle left (can't do reverse playback, back 3 frames per press instead)
    if (keyTrack[17] && keyTrack[74]) {
      document.getElementById('commentField').focus()
      this.setState({controlCommand: 'shuttleLeft'});
      return;
    };
    // CTRL + Space: Play
    if (keyTrack[17] && keyTrack[32]) {
      document.getElementById('commentField').focus()
      this.setState({controlCommand: 'playVideo'});
      return;
    };
    // CTRL + ]: Volume up
    if (keyTrack[17] && keyTrack[221]) {
      this.setState({controlCommand: 'volumeUp'});
      return;
    };
    // CTRL + [: Volume down
    if (keyTrack[17] && keyTrack[219]) {
      this.setState({controlCommand: 'volumeDown'});
      return;
    };
    // CTRL + F: Toggle full screen
    if (keyTrack[17] && keyTrack[70]) {
      this.setState({controlCommand: 'toggleFullScreen'});
      return;
    };

  };

  shortCutReleased = (event) => {
    try {
      keyTrack[event.keyCode] = false;
    } catch (error) {
      return;
    }
    return;
  };

  handleJumpTo = (seconds) => {
    this.setState({jumpToSeconds: seconds});
    this.setState({controlCommand: 'jumpToSeconds'});
  };

  handleSpeedChange = () => {
    this.setState({controlCommand: 'changeSpeed'});
  }

  handleImageComment = (imageId, imageTitle) => {
    this.setState({currentImageId: imageId});
    this.setState({currentImageTitle: imageTitle});
  };

  handleFilter = (event) => {

    if (isGlobalCommentButtonOn) {
      this.toggleGlobalCommentOff();
    };

    if (isFilterButtonOn) {
      this.toggleFilterOff();
      isFilterButtonOn = false;
      return;
    };

    this.toggleFilterOn();
    isFilterButtonOn = true;
    return;
  };

  toggleFilterOn = () => {
    document.getElementById('filterButton').className='btn btn-warning';
    document.getElementById('commentField').value='filter: ' + document.getElementById('commentField').value;
    document.getElementById('commentField').className='form-control bg-dark text-white';
    this.setState({searchString: document.getElementById('commentField').value.slice(8)});
    document.getElementById('commentField').focus();
  }

  toggleFilterOff = () => {
    document.getElementById('commentField').value = document.getElementById('commentField').value.slice(8);
    document.getElementById('filterButton').className='btn btn-outline-light';
    document.getElementById('commentField').className='form-control';
    this.setState({searchString: undefined});
    document.getElementById('commentField').focus();
  }

  handleGlobalComment = (event) => {

    if (isFilterButtonOn) {
      this.toggleFilterOff();
    };

    if (isGlobalCommentButtonOn) {
      this.toggleGlobalCommentOff();
      isGlobalCommentButtonOn = false;
      return;
    };

    this.toggleGlobalCommentOn();
    isGlobalCommentButtonOn = true;
    return;
  };

  toggleGlobalCommentOn = () => {
    document.getElementById('globalCommentButton').className='btn btn-warning';
    document.getElementById('commentField').value='global: ' + document.getElementById('commentField').value;
    document.getElementById('commentField').className='form-control bg-dark text-white';
    document.getElementById('commentField').focus();
  }

  toggleGlobalCommentOff = () => {
    document.getElementById('commentField').value = document.getElementById('commentField').value.slice(8);
    document.getElementById('globalCommentButton').className='btn btn-outline-light';
    document.getElementById('commentField').className='form-control';
    document.getElementById('commentField').focus();
  }

  render() {

    return (
      <div>
        <div className="container-fluid" style={{padding: 0}}>
          {this.state.mountMediaBrowser &&
            <MediaBrowser currentMedia={this.state.currentMedia} currentImageInfo={this.handleImageComment} controlCommand={this.state.controlCommand} seconds={this.state.jumpToSeconds} speedRate={this.state.changeSpeedRate}/>
          }
        </div>

        <div className="animated fadeIn container-fluid p-3 mb-2 bg-dark text-white">
            <form onSubmit={this.onSubmit}>
              <div className="container-fluid">
                <div className="row d-flex justify-content-center">
                  <div className="col">
                    <tt id="timecodeDisplay" className="animated fadeIn btn btn-outline-warning" name="timecode" onClick={this.handleSpeedChange}>{'00:00:00:00'}</tt>
                    <span>&nbsp;&nbsp;&nbsp;</span>
                    <span>
                      <input className="form-control" id="commentField" style={{display: "inline", width: "540px"}} type="text" name="content" placeholder="Comment goes here... Press Enter to resume playback..." spellCheck={true} autoComplete="off" onBlur={this.handleOnBlur} onChange={this.onChange} autoFocus={true}/>
                    </span>
                    <span>&nbsp;&nbsp;&nbsp;</span>
                    <span>
                      <button className="btn btn-outline-light" id="globalCommentButton" type="button" name="global" onClick={this.handleGlobalComment}><span className="fas fa-thumbtack"></span></button>
                    </span>
                    <span>&nbsp;&nbsp;&nbsp;</span>
                    <span>
                      <button className="btn btn-outline-light" id="filterButton" type="button" name="search" onClick={this.handleFilter}><span className="fas fa-search"></span></button>
                    </span>
                    <span>&nbsp;&nbsp;&nbsp;</span>
                    <span>
                      <button className="btn btn-outline-light" id="commentButton" style={{width: 120}}type="submit" name="comment"><span>Comment</span></button>
                    </span>
                    <span>&nbsp;&nbsp;&nbsp;</span>
                    <span>
                      <Sharing/>
                    </span>
                  </div>
                </div>
              </div>
              <br/>
            </form>
          <div>

            {this.state.mountCommentTable &&
              <CommentsTable searchString={this.state.searchString} currentUser={this.props.currentUser} currentMedia={this.state.currentMedia} currentImageId={this.state.currentImageId} currentImageTitle={this.state.currentImageTitle} onJumpToSeconds={this.handleJumpTo}/>
            }
            <hr/>
            <div className="container-fluid d-flex justify-content-center">
              {activeUsersBuffer.map((each, index) => (
                <span key={each.socketId} className="animated fadeIn text-white">
                  <button id={"activeUser" + each.socketId} type="button" style={{marginBottom:10, marginLeft: (index === 0 ? 0 : 10)}} className="animated fadeIn btn btn-outline-light" title={each.userName}>{each.userName.slice(0,1)}</button>
                </span>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  };
};
export default CommentWindow;
