import React, { Component } from 'react';
import axios from 'axios';

import DeclineInvitation from './DeclineInvitation';

let invitationsBuffer = [];
let currentPendingBadge = [];

class Invitation extends Component {

  constructor(props) {
    super(props);
    this.state = {
      collaboratorEmail: undefined,
    };
  };

  componentDidMount() {
    this.viewInvitations();
  }

  viewInvitations = () => {
    axios.get('/api/invitation/').then((result) => {
      invitationsBuffer = [];
      currentPendingBadge = [];
      result.data.map((each) => {
        invitationsBuffer.push(each);
        if (each.status === 'pending') {
          currentPendingBadge.push(each);
        };
        return each;
      })
      this.setState({updateContent: true});
    });
  };

  acceptInvitation = (event) => {
    event.preventDefault();
    let invitationId = event.target.getAttribute('data-invitationId');
    axios.patch('/api/invitation/' + invitationId, {action: 'accept'})
    .then((result) => {
      if (result.status === 200) {
        window.location.reload();
      };
    }, (error) => {
      console.log(error);
    });
  };

  declineInvitation = (event) => {
    event.preventDefault();
    let invitationId = event.target.getAttribute('data-invitationId');
    axios.patch('/api/invitation/' + invitationId, {action: 'decline'})
    .then((result) => {
      if (result.status === 200) {
        window.location.reload();
      };
    }, (error) => {
      console.log(error);
    });
  };

  deleteInvitation = (event) => {
    event.preventDefault();
    let invitationId = event.target.getAttribute('data-invitationId');
    axios.delete('/api/invitation/' + invitationId)
    .then((result) => {
      if (result.status === 200) {
        window.location.reload();
      };
    }, (error) => {
      console.log(error);
    });
  };

  cancelInvitation = (event) => {
    event.preventDefault();
    let invitationId = event.target.getAttribute('data-invitationId');
    axios.patch('/api/invitation/' + invitationId, {action: 'cancel'})
    .then((result) => {
      if (result.status === 200) {
        window.location.reload();
      };
    }, (error) => {
      console.log(error);
    });
  };

  render() {

    return (
      <div>

      <button type="button" className="col btn btn-primary" data-toggle="modal" data-target="#showInvitationsModal" onClick={this.viewInvitations}>
      {currentPendingBadge.length !== 0 &&
      <span className="animated fadeIn badge badge-danger" style={{marginRight:5}}>{currentPendingBadge.length}</span>
      }
      <span>Invitations</span>
      </button>

        <div className="modal text-dark fade" id="showInvitationsModal" style={{backgroundColor:'rgba(0, 0, 0, 0.5)'}} data-backdrop="false" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
          <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
            <div className="modal-content" >
              <div className="modal-header">
                <h5 className="modal-title" id="showInvitationsModalTitle">Invitations</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>

              <div className="modal-body">

              <table className="table">
                <thead>
                  <tr>
                    <th scope="col"><small>Invitee</small></th>
                    <th scope="col"><small>Project</small></th>
                    <th scope="col"><small>Status</small></th>
                    <th scope="col"><small>Action</small></th>
                  </tr>
                </thead>
                <tbody>
                {invitationsBuffer.map((each) => (
                  [((each.invitee.id === this.props.currentUser.id) && (each.status === 'pending')) &&
                    <tr>
                    <td><small>{each.invitee.userName}</small></td>
                    <td><small>{each.project.name}</small></td>
                    <td><small>{each.status}</small></td>
                    {((each.inviter.id === this.props.currentUser.id) && (each.status === 'pending')) &&
                    <td><button className="badge badge-danger text-dark" id={"cancelButton" + each.id} data-invitationId={each.id} type="button" name="cancel" onClick={this.cancelInvitation}>Cancel</button></td>
                    }
                    {((each.inviter.id === this.props.currentUser.id) && (each.status === 'accepted')) &&
                    <td><button className="badge badge-primary text-white" id={"removeButton" + each.id} data-invitationId={each.id} type="button" name="remove" onClick={this.removeInvitation}>Remove</button></td>
                    }
                    {((each.invitee.id === this.props.currentUser.id) && (each.status === 'pending')) &&
                    <td>
                      <span>
                      <i className="far fa-check-circle" id={"acceptButton" + each.id} data-invitationId={each.id} style={{cursor: "pointer"}} onClick={this.acceptInvitation}></i>
                      <span>&nbsp;&nbsp;&nbsp;</span>
                      <DeclineInvitation currentInvitation={each} />
                      </span>
                    </td>
                    }
                    </tr>
                  ]
                ))}
                {invitationsBuffer.map((each) => (
                  [each.inviter.id === this.props.currentUser.id &&
                    <tr>
                      <td><small>{each.invitee.userName}</small></td>
                      <td><small>{each.project.name}</small></td>
                      <td><small>{each.status}</small></td>
                      {each.status === 'pending' &&
                        <td><span className="badge badge-danger text-dark" id={"cancelButton" + each.id} data-invitationId={each.id} type="button" name="cancel" onClick={this.cancelInvitation}>Cancel</span></td>
                      }
                      {each.status !== 'pending' &&
                        <td><button className="badge badge-primary text-white" id={"removeButton" + each.id} data-invitationId={each.id} type="button" name="remove" onClick={this.deleteInvitation}>Remove</button></td>
                      }
                      </tr>
                    ]
                ))}
                </tbody>
              </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Invitation;
