import React, { Component } from 'react';
import UpdateAccount from './UpdateAccount';
import UpdatePassword from './UpdatePassword';
import DropboxAccessControl from './DropboxAccessControl';

class Account extends Component {

  constructor(props) {
    super(props);
    this.state = {};
  };

  render() {
    return (
      <div className="animated fadeIn container-fluid text-white">

        <div className="animated fadeInDown jumbotron jumbotron-fluid bg-primary">
          <div className="container">
          <h1 className="display-4">Welcome, {this.props.currentUser.userName}</h1>
          <p className="lead">Everything about your account, in one place</p>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <div className="jumbotron bg-secondary" style={{height:350}}>
              <h1 className="display-4">Profile</h1>
              <p className="lead">Your OC Identity</p>
              <hr className="my-4"/>
              <p>Update your login email or user name.</p>
              <UpdateAccount currentUser={this.props.currentUser}/>
            </div>
          </div>
          <div className="col">
            <div className="jumbotron bg-secondary" style={{height:350}}>
              <h1 className="display-4">Password</h1>
              <p className="lead">The key that logs you in</p>
              <hr className="my-4"/>
              <p>Update your password.</p>
              <UpdatePassword currentUser={this.props.currentUser}/>
            </div>
          </div>
        </div>


        <div className="row">
          <div className="col">
            <div className="jumbotron bg-secondary" style={{height:300}}>
              <h1 className="display-4">Dropbox</h1>
              <p className="lead">Your current Dropbox authentication status is: {this.props.currentUser.dropbox !== 'none' ? 'Authorized' : 'Unauthorized'}</p>
              <hr className="my-4"/>
              <p></p>
              <DropboxAccessControl currentUser={this.props.currentUser} />
            </div>
          </div>
        </div>

    </div>
    )
  }
}

export default Account;
