// import core libraries
import React, { Component } from 'react';
import axios from 'axios';
// eslint-disable-next-line
import { BrowserRouter as Router, Route, Link, Switch, Redirect } from 'react-router-dom';

// import component
import './App.css';
// import logo from './logo.svg';
import Login from './components/Login/Login';
import SignUp from './components/SignUp/SignUp';
import PassReset from './components/PassReset/PassReset'
import Account from './components/Dashboard/Account';
import CommentWindow from './components/Comment/CommentWindow';
import ImportMedia from './components/Dashboard/ImportMedia';
import MediaLibrary from './components/Dashboard/MediaLibrary';
import ProjectLibrary from './components/Dashboard/Project/ProjectLibrary';
import ProjectWidget from './components/Dashboard/Project/ProjectWidget';
import ProjectOverview from './components/Dashboard/Project/ProjectOverview';

// eslint-disable-next-line
import Home from './components/Home/Home';
import MultiCamViewer from './components/Comment/MultiCamViewer';

const ProjectButton = () => (
  <li className={window.location.pathname==="/project-library" ? "animated fadeIn nav-item active" : "animated fadeIn nav-item"}>
    <Link className="nav-link" to="/project-library">Project</Link>
  </li>
);

// App starts here
class App extends Component {

  constructor() {
    super();
    this.state = {
      isAuthorized: false,
      updateContent: false,
      currentUser: {id:null, userName:null, email:null}
    };
    this.handleLogIn = this.handleLogIn.bind(this);
  };

  componentDidMount() {
    this.verifyLogin();
  };

  verifyLogin = () => {

    axios.get('/api/authorized')
    .then((result) => {
      if (result.status === 200) {
        this.setState(result.data);
        this.setState({updateContent: true});
        return;
      };
    }, (error) => {
      if (error.response.status === 401) {
        this.setState({isAuthorized: false});
        this.setState({updateContent: true});
        setTimeout(function() {
          if (!this.state.isAuthorized) {
            if (window.location.pathname === '/signup') {return};
            if (window.location.pathname === '/login') {return};
            if (window.location.pathname.split('/')[1] === 'password-reset') {return};
            if (window.location.pathname === '/') {return};
            window.location.assign('/login?next=' + window.location.href);
          };
        }.bind(this), 1000);
      };
    });
  };

  handleLogIn = (sessionData, redirect, next) => {
    this.setState(sessionData);
    if (redirect) {
      let redirectURL = String(window.location.protocol + '//' + window.location.host + next)
      window.location.replace(redirectURL);
    } else {
      window.location.assign('/');
    };
  };

  logOut = (event) => {
    axios.get('/logout')
    .then((result) => {
      this.setState({isAuthorized: false});
      window.location.assign('/');
    }, (error) => {
      console.log(error);
    });
  };

  render() {

    // disable space-bar scrolling
    window.addEventListener('keydown', function(e) {
      if(e.keyCode === 32 && e.target === document.body) {
        e.preventDefault();
      }
    });

    return (
      <Router>

      <div className="App">

        <header className="App-header">

          <span className="animated fadeIn">
          <nav className="navbar navbar-expand-lg navbar-dark" style={{background: '000000'}}>
            <a className="navbar-brand" href="/"><img src={'/images/logo.svg'} className="App-logo" alt="Logo"/> OpinionCity</a>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav mr-auto">
                  {this.state.isAuthorized &&
                    <Switch>
                      <Route exact path="/media/:id" render={(props) => (
                        <ProjectWidget {...props} currentUser={this.state.currentUser}/>
                      )}/>
                      <Route exact path="/album/:id" render={(props) => (
                        <ProjectWidget {...props} currentUser={this.state.currentUser}/>
                      )}/>
                      <Route path='/' component={ProjectButton} />
                    </Switch>
                  }
                  {this.state.isAuthorized &&
                    <li id="mediaLibraryModuleButton" className="animated fadeIn nav-item">
                      <Link className="nav-link" to="/media-library">Media</Link>
                    </li>
                  }
                  {this.state.isAuthorized &&
                    <li id="importModuleButton" className="animated fadeIn nav-item">
                      <Link className="nav-link" to="/import">Import</Link>
                    </li>
                  }
                </ul>
                <ul className="navbar-nav">
                    {this.state.isAuthorized &&
                      <li className="nav-item">
                        <Link className="nav-link" to='/account' data-toggle="tooltip" title={'Logged in as ' + this.state.currentUser.userName}>Account</Link>
                      </li>
                    }
                </ul>

                {(!this.state.isAuthorized && this.state.updateContent) &&
                  <ul className="animated fadeIn navbar-nav">
                  <li className={window.location.pathname==="/login" ? "nav-item active" : "nav-item"} >
                    <Link className="nav-link" to="/login">Login</Link>
                  </li>
                  <li className={window.location.pathname==="/signup" ? "nav-item active" : "nav-item"} >
                    <Link className="nav-link" to="/signup">Sign Up</Link>
                  </li>
                  </ul>
                }
                {(this.state.isAuthorized && this.state.updateContent) &&
                  <ul className="animated fadeIn navbar-nav">
                    <li className="nav-item">
                      <span className="nav-link" onClick={this.logOut} style={{cursor: "pointer"}}>Log Out</span>
                    </li>
                  </ul>
                }

                {false &&
                  <form className="form-inline my-2 my-lg-0">
                    <input className="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search"/>
                    <button className="btn btn-outline-success my-2 my-sm-0" type="submit">Search</button>
                  </form>
                }
              </div>
            </nav>
          </span>


        </header>

        <div style={{marginBottom:15}}></div>

        <Route exat path = "/multicam/" render={(props) => (
          <MultiCamViewer {...props} currentUser={this.state.currentUser}/>
        )}/>

        <Route path="/login" render={(props) => (
          <Login {...props} onLoggedIn={this.handleLogIn} isAuthorized={this.state.isAuthorized}/>
        )}/>
        <Route path="/signup" render={(props) => (
          <SignUp {...props} onLoggedIn={this.handleLogIn} isAuthorized={this.state.isAuthorized}/>
        )}/>
        <Switch>
        <Route exact path="/password-reset/:token" render={(props) => (
          <PassReset {...props} />
        )}/>
        <Route exact path="/password-reset" render={(props) => (
          <PassReset {...props} />
        )}/>
        </Switch>
        <Route exact path='/' component={Home} />
        {this.state.isAuthorized &&
          <Route path="/account" render={(props) => (
            <Account {...props} currentUser={this.state.currentUser}/>
          )}/>
        }
        {this.state.isAuthorized &&
          <Route path="/import" render={(props) => (
            <ImportMedia {...props} currentUser={this.state.currentUser}/>
          )}/>
        }
        {this.state.isAuthorized &&
          <Route exact path="/media/:id" render={(props) => (
            <CommentWindow {...props} currentUser={this.state.currentUser}/>
          )}/>
        }
        {this.state.isAuthorized &&
          <Route exact path="/album/:id" render={(props) => (
            <CommentWindow {...props} currentUser={this.state.currentUser}/>
          )}/>
        }
        {this.state.isAuthorized &&
          <Route path="/media-library" render={(props) => (
            <MediaLibrary {...props} currentUser={this.state.currentUser}/>
          )}/>
        }
        {this.state.isAuthorized &&
          <Route path="/project-library" render={(props) => (
            <ProjectLibrary {...props} currentUser={this.state.currentUser}/>
          )}/>
        }
        {this.state.isAuthorized &&
          <Route exact path="/project/:projectId" render={(props) => (
            <ProjectOverview {...props} currentUser={this.state.currentUser}/>
          )}/>
        }
        <div>
          <hr/>
          <p className="text-secondary text-center">{'© OpinionCity ' + new Date().getFullYear()}</p>
        </div>
      </div>
      </Router>
    );}
}

export default App;
