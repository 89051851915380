module.exports = {

  convertToTimecode: function (input) {

    function fillZero(input) {
      if (input < 10) { input = String('0' + input); }
      return input;
    };

    let rawInput = 0;
    let frame = 0;
    let second = 0;
    let minute = 0;
    let hour = 0;
    let output = 0;

    rawInput = input;
    input = parseInt(input, 10);
    frame = parseInt((rawInput - input) * 30, 10);
    frame = fillZero(frame);

    if (input <= 59) {
      second = fillZero(input);
      minute = fillZero(0);
      hour = fillZero(0);
    } else if (input > 59 && input <= 3600) {
      minute = parseInt(input/60, 10);
      second = input % 60;
      minute = fillZero(minute);
      second = fillZero(second);
      hour = fillZero(0);
    } else if (input > 3600 && input <= 360000) {
      hour = parseInt(input/3600, 10);
      minute = parseInt((input % 3600)/60, 10);
      second = input % 60
      hour = fillZero(hour);
      minute = fillZero(minute);
      second = fillZero(second);
    } else {
      output = 'TIMECODE ERROR'
      return output;
    };
    output = String(hour + ':' + minute + ':' + second + ':' + frame);
    return output;
  },

  timeParser: function (input) {

    let hour = 0;
    let minute = 0;
    let second = 0;

    if (input === undefined) {
      return;
    };

    if (input.indexOf('h') !== -1) {
      hour = input.split('h')[0];
    };

    if (input.indexOf('m') !== -1) {
      minute = input.split('m')[0];
      if (minute.indexOf('h') !== -1) {
        minute = minute.split('h')[1];
      }
    };

    if (input.indexOf('s') !== -1) {
      second = input.split('s')[0];
      if (second.indexOf('m') !== -1) {
        second = second.split('m')[1];
      }
    };

    hour = parseInt(hour);
    minute = parseInt(minute);
    second = parseInt(second);

    return (hour*3600 + minute*60 + second);

  },

  // a function that sort the array by attribute
  sortByKey: function (array, key, type) {
    if (type === 'ascend') {
      return array.sort(function(a, b) {
        let x = a[key]; let y = b[key];
        return ((x < y) ? -1 : ((x > y) ? 1 : 0));
      });
    };
    if (type === 'descend') {
      return array.sort(function(a, b) {
        let x = a[key]; let y = b[key];
        return ((x > y) ? -1 : ((x < y) ? 1 : 0));
      });
    };
  },

  stringCheck: function (string) {

    if (string === undefined) { return false };

    const nonWhiteSpace = /[^\s\\]/;
    if (string.match(nonWhiteSpace) === null) {
      return false;
    };

    return true;
  },

  pwdComplexityCheck: function (password) {
    let regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!@#$%^&*(),.?":{}|<>]{8,}$/
    if (password.match(regex)) {
      return true
    }
    return false
  }

}
