import React, { Component } from 'react';
import axios from 'axios';

class DeleteMedia extends Component {

  constructor(props) {
    super(props);
    this.state = {};
  };

  deleteMedia = (event) => {

    event.preventDefault();
    let targetElement = document.getElementById('mediaRow' + this.props.currentMedia.id);
    let currentMediaId = this.props.currentMedia.id;

    document.getElementById('deleteMediaButton' + this.props.currentMedia.id).setAttribute('disabled', 'true');
    document.getElementById('deleteMediaButton' + this.props.currentMedia.id).innerHTML = 'DELETING...';

    if (this.props.currentMedia.type !== 'Album') {
      axios.delete('/api/media/' + currentMediaId)
      .then((result) => {
        if (result.status === 200) {
          targetElement.className = 'animated fadeOut row';
          setTimeout(function() {targetElement.style.display = 'none';}, 500);
        };
      }, (error) => {
        document.getElementById('deleteMediaButton' + this.props.currentMedia.id).innerHTML = 'Failed';
        document.getElementById('deleteMediaButton' + this.props.currentMedia.id).removeAttribute('disabled');
      });
      return;
    };

    if (this.props.currentMedia.type === 'Album') {
      axios.delete('/api/album/' + currentMediaId)
      .then((result) => {
        if (result.status === 200) {
          targetElement.className = 'animated fadeOut row';
          setTimeout(function(){targetElement.style.display = 'none';}, 500);
        };
      }, (error) => {
        document.getElementById('deleteMediaButton' + this.props.currentMedia.id).innerHTML = 'Failed';
        document.getElementById('deleteMediaButton' + this.props.currentMedia.id).removeAttribute('disabled');
      });
      return;
    };
    return;
  };


  render() {

    return (
      <span>

        {this.props.currentMedia !== undefined &&
        <span>

        <span>
          <i className="fas fa-trash" style={{cursor: "pointer"}} data-toggle="modal" data-target={"#deleteMediaModal" + this.props.currentMedia.id}/>
        </span>

        <div className="modal text-dark fade" id={"deleteMediaModal" + this.props.currentMedia.id} style={{backgroundColor:'rgba(0, 0, 0, 0.5)'}} data-backdrop="false" tabIndex="-1" role="dialog" aria-labelledby="deleteMediaModalTitle" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title" id={"deleteMediaModalTitle" + this.props.currentMedia.id}>{"WARNING"}</h4>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
              <form id="DeleteMediaForm" onSubmit={this.deleteMedia}>
                <h5>Are you sure you want to delete media: {this.props.currentMedia.title}?</h5>
                <div className="modal-footer">
                  <button id={"deleteMediaButton" + this.props.currentMedia.id} type="submit" className="btn btn-danger">DELETE</button>
                  <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancel</button>
                </div>
              </form>
              </div>
            </div>
          </div>
        </div>

        </span>
      }
      </span>
    )
  }
}

export default DeleteMedia;
