import React, { Component } from 'react';
import axios from 'axios';

import Collaboration from './Collaboration';
import UTIL from './../../UtilityFunction/UtilityFunction'

let mediaBuffer = [];
let imageArray = [];
let currentExpandedAlbum;

class ProjectOverview extends Component {

  constructor(props) {
    super(props);
    this.state = {
      currentProject: undefined,
      didFetchAlbum: false,
      updateContent: false,
      multiCamCompatible: true
    };
  };

  componentDidMount() {
    setTimeout(function() {
      this.retrieveProject();
    }.bind(this), 100);
  };

  retrieveProject = () => {
    axios.get('/api/project/' + this.props.match.params.projectId)
    .then((result) => {
      if (result.status === 200) {
        try {
          this.setState({currentProject: result.data});
          mediaBuffer = this.state.currentProject.mediaArray;
          if (this.state.currentProject.mediaArray.length < 2) {
            this.setState({multiCamCompatible: false})
          } else {
            mediaBuffer.some((each) => {
              if (each.type !== "Video") {
                this.setState({multiCamCompatible: false})
              }
              return each
            })
          }
          UTIL.sortByKey(mediaBuffer, 'title', 'ascend');
          this.setState({updateContent: true});
        } catch (error) {
          console.log(error);
        };
      };
    }, (error) => {
      window.location.assign('/project-library');
    });
  };

  fetchAlbum = (event) => {

    let currentTarget = event.target;

    try {
      if (currentTarget === currentExpandedAlbum) {
        currentExpandedAlbum = null;
        return;
      };
      currentExpandedAlbum.click();
    } catch (error) {};

    this.setState({currentAlbumId: false});

    axios.get('/api/album/' + event.target.getAttribute('data-sourcemedia'))
    .then((result) => {
      if (result.status === 200) {
        imageArray = result.data.imageArray.map((each) => {
          return each;
        });
        currentExpandedAlbum = currentTarget;
        this.setState({currentAlbumId: result.data.id});
        this.setState({didFetchAlbum: true});
      };
    }, (error) => {
      this.setState({serverResponse: error.response});
    });
  };

  removeMediaFromProject = (event) => {
    event.preventDefault();

    let mediaId = event.target.id;
    let targetElement = document.getElementById('mediaRow' + mediaId);

    axios.patch('/api/project/media/remove/' + event.target.id, {projectId: this.props.match.params.projectId})
    .then((result) => {

      try {
        targetElement.className = 'animated fadeOut row';
      } catch (error) {}

      setTimeout(function() {
        try {
          targetElement.style.display = 'none';
          mediaBuffer = mediaBuffer.filter(function(each) {
            return each.id !== mediaId;
          });
          this.setState({updateContent: true});
        } catch (error) {};
      }, 500);
    }, (error) => {
      console.log(error);
    });
  };

  render() {

    return (
        <div>

        {this.state.updateContent &&
        <div className="animated fadeIn container-fluid text-white">
          <div>
            <br/>
            <div className="row">
            <span className="col">
              <h3 className="text-left text-white col">Project Overview - {this.state.currentProject.name}</h3>
              <h5 className="text-left text-white col">Description: {this.state.currentProject.description}</h5>
            </span>
            <span className="col container-fluid d-flex justify-content-end">
              <Collaboration className="align-bottom" currentProject={this.state.currentProject} currentUser={this.props.currentUser} multiCamCompatible={this.state.multiCamCompatible}/>
            </span>
            </div>
            <br/>
          </div>

          <table className="table table-striped table-hover">
            <thead className="thead-dark">

              <tr className="row" style={{paddingLeft:15, paddingRight:15}}>
                <th style={{width:'20%'}} scope="col" className="text-left">Title</th>
                <th style={{width:'40%'}} scope="col" className="text-left">Description</th>
                <th style={{width:'10%'}} scope="col">Type</th>
                <th style={{width:'10%'}} scope="col">Provider</th>
                <th style={{width:'10%'}} scope="col">Action</th>
                <th style={{width:'10%'}} scope="col">Uploaded by</th>
              </tr>

            </thead>
          </table>

          <div style={{display: "block", maxHeight:'400px', overflowY: 'auto', overflowX: 'hidden'}}>
          <table className="animated fadeIn table table-striped table-hover">
            <tbody>
              {mediaBuffer.length === 0 &&
                <div className="animated fadeIn">No media in media library</div>
              }
              {mediaBuffer.map((each) => (
                <div className="container-fluid" key={each.id}>
                  <tr className="row" style={{padding:0}} id={"mediaRow" + each.id}>

                    <td style={{ width: "20%" }} className="text-left">
                      <a className="text-white" target={true ? "" : "_blank"} href={each.type !== 'Album' ? "/media/" + each.id : "/album/" + each.id}>
                      {each.title}
                    </a>
                      {each.type === "Album" &&
                      <span>
                      <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                      <i className="far fa-caret-square-down" style={{cursor: "pointer"}} data-sourceMedia={each.id} data-toggle="collapse" data-target={"#albumExpand" + each.id} onClick={this.fetchAlbum}></i>
                      </span>
                    }
                    </td>
                    <td style={{width:'40%'}} className="text-left">{each.description}</td>
                    <th style={{width:'10%'}}>{each.type}</th>
                    <td style={{width:'10%'}}>
                      <a className="text-white" href={"http://www." + each.provider + ".com"}><i className={"fab fa-" + each.provider.toLowerCase()} style={{transform: "scale(1.5)"}}></i></a>
                    </td>

                    <td style={{width:'10%'}}>
                      {this.state.currentProject.owner === this.props.currentUser.id &&
                        <button id={each.id} type="button" className="btn btn-danger" onClick={this.removeMediaFromProject}>Remove</button>
                      }
                    </td>
                    <td style={{width:'10%'}}>{each.owner.userName}</td>
                    </tr>

                    <div className="collapse text-white" id={"albumExpand" + each.id}>
                    <div className="row container-fluid">
                    {this.state.didFetchAlbum && imageArray.map((eachImage) => (
                      <div className="animated fadeIn content col-sm-2" id={"image" + eachImage.id} key={eachImage.id}>
                      <div className="content-overlay"></div>
                          <img className="content-image" alt={eachImage.title} src={eachImage.thumbnailUrl} />
                          <div className="content-details fadeIn-bottom">
                          <a href={'/album/' + this.state.currentAlbumId + '?image=' + eachImage.id}>
                          <h4 className="content-title">View</h4>
                          </a>
                            <br/>
                            <br/>
                            <span className="text-danger" style={{cursor: "pointer"}} data-imageid={eachImage.id} onClick={this.deleteImage}>Delete</span>
                          </div>
                      </div>
                    ))}
                    </div>
                    {!this.state.didFetchAlbum &&
                      <div className="spinner">
                        <div className="double-bounce1"></div>
                        <div className="double-bounce2"></div>
                      </div>
                    }
                    </div>
                  </div>
                ))}
              </tbody>
            </table>
          </div>

        </div>
      }
      {!this.state.updateContent &&
        <div className="spinner">
          <div className="double-bounce1"></div>
          <div className="double-bounce2"></div>
        </div>
      }
      </div>
    )
  }
}

export default ProjectOverview;
