import React, { Component } from 'react';
import axios from 'axios';

class DropboxAccessControl extends Component {

  constructor(props) {
    super(props);
    this.state = {
      email: ''
    };
  };

  unlinkDropbox = (event) => {
    event.preventDefault();

    document.getElementById('unlinkDropboxButton').setAttribute('disabled', 'true');

    axios.patch('/api/user/unlinkDropbox')
    .then((result) => {
      if (result.status === 200) {
        window.location.reload();
      };
    }, (error) => {
      console.log(error);
      document.getElementById('unlinkDropboxButton').innerHTML = 'Failed';
      document.getElementById('unlinkDropboxButton').removeAttribute('disabled');
    })
  }

  connectDropbox = (event) => {
    window.location.assign('/auth/dropbox');
  };

  render() {

    return (
      <span>

      {this.props.currentUser.dropbox === 'none' &&
      <button type="button" className="btn btn-warning" data-toggle="modal" data-target="#connectDropboxModal">
        Connect Dropbox
      </button>
      }

      {this.props.currentUser.dropbox !== 'none' &&
      <button type="button" className="btn btn-warning" data-toggle="modal" data-target="#unlinkDropboxModal">
        Unlink
      </button>
      }

      <div className="modal text-dark fade" id="connectDropboxModal" style={{backgroundColor:'rgba(0, 0, 0, 0.8)'}} data-backdrop="false" tabIndex="-1" role="dialog" aria-labelledby="connectDropboxModalTitle" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" id="connectDropboxModalTitle">Connect Dropbox</h4>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
            <form id="connectDropboxForm">
              <h5>You will be redirected to Dropbox authentication page, continue?</h5>
              <div className="modal-footer">
                <button type="button" className="btn btn-primary" onClick={this.connectDropbox}>Connect</button>
                <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancel</button>
              </div>
            </form>
            </div>
          </div>
        </div>
      </div>

        <div className="modal text-dark fade" id="unlinkDropboxModal" style={{backgroundColor:'rgba(0, 0, 0, 0.8)'}} data-backdrop="false" tabIndex="-1" role="dialog" aria-labelledby="unlinkDropboxModalTitle" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title" id="unlinkDropboxModalTitle">Unlink Dropbox</h4>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
              <form id="unlinkDropboxForm" onSubmit={this.unlinkDropbox}>
                <h5>Are you sure you want to unlink Dropbox?</h5>
                <div className="modal-footer">
                  <button id="unlinkDropboxButton" type="submit" className="btn btn-danger">UNLINK</button>
                  <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancel</button>
                </div>
              </form>
              </div>
            </div>
          </div>
        </div>

      </span>
    )
  }
}

export default DropboxAccessControl;
