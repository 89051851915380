import React, { Component } from 'react';

class NotificationCenter extends Component {

  constructor(props) {
    super(props);
    this.state = {
      serverResponse: this.props.serverResponse
    };
  };

  componentDidMount() {
    this.setState({serverResponse: this.props.serverResponse})
  };

  componentWillReceiveProps({serverResponse}) {

    if (!serverResponse) { return; };

    try {
      if (serverResponse.status === 401 ) {
        this.setState({serverResponse: serverResponse});
        document.getElementById('notificationBody').innerHTML = serverResponse.data.message;
        document.getElementById('triggerNotification').click();
      };
    } catch (error) { console.log(error) };

    try {
      if (serverResponse.status === 403) {
        this.setState({serverResponse: serverResponse})
        document.getElementById('notificationBody').innerHTML = serverResponse.data.message;
      };
    } catch (error) { console.log(error) };

    try {
      if (serverResponse.status === 404) {
        this.setState({serverResponse: serverResponse})
        document.getElementById('notificationBody').innerHTML = serverResponse.data.message;
      };
    } catch (error) { console.log(error) };

    try {
      if (serverResponse.status === 500) {
        this.setState({serverResponse: serverResponse})
        document.getElementById('notificationBody').innerHTML = serverResponse.data.message;
        document.getElementById('triggerNotification').click();
      };
    } catch (error) { console.log(error) };

  }

  action = (event) => {
    if (this.state.serverResponse.status === 401) {
      localStorage.removeItem('ocv2session');
      localStorage.removeItem('ocv2tempSession');
      window.location.assign('/login');
    };
    return;
  };


  render() {
    return (

      <div>

      <button hidden id="triggerNotification" type="button" className="btn btn-primary" data-toggle="modal" data-target="#exampleModal">
        Launch demo modal
      </button>

      <div id="exampleModal" className="modal text-dark fade show" data-backdrop="false" tabIndex="-1" role="dialog">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">OpinionCity</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p id="notificationBody">Modal body text goes here.</p>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={this.action}>OK</button>
            </div>
          </div>
        </div>
      </div>

      </div>
    );
  }
}

export default NotificationCenter;
