import React, { Component } from 'react';
import axios from 'axios';
import NotificationCenter from '../NotificationCenter/NotificationCenter';
import EditMedia from './EditMedia';
import AddMediaToProject from './AddMediaToProject';
import DeleteMedia from './DeleteMedia';
import './MediaLibrary.css';

const UTIL = require('../UtilityFunction/UtilityFunction');

let mediaBuffer = [];
let imageArray = [];
let currentExpandedAlbum;

let sortedByTitleAscend = false;
let sortedByTypeAscend = false;
let sortedByProviderAscend = false;

let searchBuffer = [];
let isSearching = false;

class MediaLibrary extends Component {

  constructor() {
    super();
    this.state = {
      updateContent: false,
      didFetchAlbum: false,
      currentAlbumId: undefined
    };
  };

  componentDidMount() {
    this.setState({currentUser: this.props.currentUser});
    setTimeout(function(){
      this.fetchMedia();
    }.bind(this), 100);

    document.getElementById("mediaLibraryModuleButton").className="animated fadeIn nav-item active"
  };

  componentWillUnmount() {
    document.getElementById("mediaLibraryModuleButton").className="animated fadeIn nav-item"
  }

  fetchMedia = () => {

    axios.get('/api/media')
    .then((result) => {
      if (result.status === 200) {
        result.data.map((each) => {
          mediaBuffer.push(each);
          return each;
        });
        axios.get('/api/album').then((result) => {
          if (result.status === 200) {
            result.data.map((each) => {
              mediaBuffer.push(each);
              return each;
            });
            UTIL.sortByKey(mediaBuffer, 'title', 'ascend');
            this.setState({updateContent: true});
          };
        });
      };
    }, (error) => {
      console.log(error)
      console.log(error);
    });
  };

  fetchAlbum = (event) => {

    let currentTarget = event.target;

    try {
      if (currentTarget === currentExpandedAlbum) {
        currentExpandedAlbum = null;
        return;
      };
      currentExpandedAlbum.click();
    } catch (error) {};

    this.setState({currentAlbumId: false});

    axios.get('/api/album/' + event.target.getAttribute('data-sourcemedia'))
    .then((result) => {
      if (result.status === 200) {
        imageArray = result.data.imageArray.map((each) => {
          return each;
        });
        currentExpandedAlbum = currentTarget;
        this.setState({currentAlbumId: result.data.id});
        this.setState({didFetchAlbum: true});
      };
    }, (error) => {
      console.log(error);
    });
  };

  sortByTitle = () => {

    if (sortedByTitleAscend) {
      UTIL.sortByKey(searchBuffer, 'title', 'descend');
      sortedByTitleAscend = false;
      this.setState({updateContent: true});
      return;
    };

    UTIL.sortByKey(searchBuffer, 'title', 'ascend');
    sortedByTitleAscend = true;
    this.setState({updateContent: true});
    return;
  };

  sortByType = () => {

    if (sortedByTypeAscend) {
      UTIL.sortByKey(searchBuffer, 'type', 'descend');
      sortedByTypeAscend = false;
      this.setState({updateContent: true});
      return;
    };

    UTIL.sortByKey(searchBuffer, 'type', 'ascend');
    sortedByTypeAscend = true;
    this.setState({updateContent: true});
    return;
  };

  sortByProvider = () => {

    if (sortedByProviderAscend) {
      UTIL.sortByKey(searchBuffer, 'type', 'descend');
      sortedByProviderAscend = false;
      this.setState({updateContent: true});
      return;
    };

    UTIL.sortByKey(searchBuffer, 'type', 'ascend');
    sortedByProviderAscend = true;
    this.setState({updateContent: true});
    return;
  };

  filterMedia = (event) => {

    if (event.target.value === '') {
      isSearching = false;
      this.setState({updateContent: true});
      return;
    };

    searchBuffer = mediaBuffer.slice(0);

    searchBuffer = searchBuffer.filter((each) => {
      return (each.title.toLowerCase()).includes(event.target.value.toLowerCase());
    });

    isSearching = true;
    this.setState({updateContent: true});
  };

  deleteImage = (event) => {
    event.preventDefault();
    let currentImageId = event.target.getAttribute('data-imageid')
    axios.delete('/api/media/' + currentImageId)
    .then((result) => {
      if (result.status === 200) {
        imageArray = imageArray.filter((each) => {
          return each.id !== currentImageId;
        });
        this.setState({didFetchAlbum: true});
      };
    }, (error) => {
      console.log(error);
    });
  }

  render() {

      if (!isSearching) {
        // can't use slice(0) here, sorting won't work otherwise
        searchBuffer = mediaBuffer;
      };

      return (
        <div>
          <NotificationCenter serverResponse={this.state.serverResponse} />

          {this.state.updateContent &&
          <div className="animated fadeIn container-fluid text-white">

            <div className="row">
              <h3 className="col text-left text-white">Media Library</h3>
              <input className="col-2 form-control" type="text" style={{marginRight: "15px"}} placeholder="Search" onChange={this.filterMedia}/>
            </div>
            <br/>

            <table className="table table-striped table-hover">
              <thead className="thead-dark">
                <div className="container-fluid">
                <tr className="row">
                  <th style={{width:'20%', cursor: "pointer"}} scope="col" className="text-left" onClick={this.sortByTitle}>Title&nbsp;<i className="fas fa-caret-down"></i></th>
                  <th style={{width:'40%'}} scope="col" className="text-left">Description</th>
                  <th style={{width:'10%', cursor: "pointer"}} scope="col" onClick={this.sortByType}>Type&nbsp;<i className="fas fa-caret-down"></i></th>
                  <th style={{width:'10%', cursor: "pointer"}} scope="col" onClick={this.sortByProvider}>Provider&nbsp;<i className="fas fa-caret-down"></i></th>
                  <th style={{width:'10%'}} scope="col">Action</th>
                  <th style={{width:'10%'}} scope="col">Owner</th>
                </tr>
                </div>
              </thead>
            </table>

              <table className="animated fadeIn table table-striped table-hover">
                <tbody>
                  {searchBuffer.length === 0 &&
                    <div className="container-fluid">
                      <div className="row">
                        <div className="col animated fadeIn container-fluid text-secondary text-center">No media to display</div>
                      </div>
                    <br/>
                      <div className="row">
                        <a className="col btn btn-primary" href="/import">Import Media</a>
                      </div>
                    </div>
                  }
                  {searchBuffer.map((each) => (
                    <div className="container-fluid" key={each.id}>
                      <tr className="row" id={"mediaRow" + each.id}>

                        <td style={{ width: "20%" }} className="text-left">
                          <a className="text-white" target={true ? "" : "_blank"} href={each.type !== 'Album' ? "/media/" + each.id : "/album/" + each.id}>
                          {each.title}
                        </a>
                          {each.type === "Album" &&
                          <span>
                          <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                          <i className="far fa-caret-square-down" style={{cursor: "pointer"}} data-sourcemedia={each.id} data-toggle="collapse" data-target={"#albumExpand" + each.id} onClick={this.fetchAlbum}></i>
                          </span>
                        }
                        </td>
                        <td style={{width:'40%'}} className="text-left">{each.description}</td>
                        <th style={{width:'10%'}}>{each.type}</th>
                        <td style={{width:'10%'}}>
                          <a className="text-white" href={"http://www." + each.provider + ".com"}><i className={"fab fa-" + each.provider.toLowerCase()} style={{transform: "scale(1.5)"}}></i></a>
                        </td>

                        <td style={{width:'10%'}}>
                          <AddMediaToProject currentUser={this.state.currentUser} currentMedia={each} />
                          <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                          <EditMedia currentMedia={each} />
                          <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                          <DeleteMedia currentMedia={each} />
                        </td>
                        <td style={{width:'10%'}}>{each.owner.userName}</td>
                        </tr>
                        <div className="collapse text-white" id={"albumExpand" + each.id}>
                        <div className="row container-fluid">
                        {this.state.didFetchAlbum && imageArray.map((eachImage) => (
                          <div className="animated fadeIn content col-sm-2" id={"image" + eachImage.id} key={eachImage.id}>
                          <div className="content-overlay"></div>
                              <img className="content-image" alt={eachImage.title} src={eachImage.thumbnailUrl} />
                              <div className="content-details fadeIn-bottom">
                              <a href={'/album/' + this.state.currentAlbumId + '?image=' + eachImage.id}>
                              <h4 className="content-title">View</h4>
                              </a>
                                <br/>
                                <br/>
                                <span className="text-danger" style={{cursor: "pointer"}} data-imageid={eachImage.id} onClick={this.deleteImage}>Delete</span>
                              </div>
                          </div>
                        ))}
                        </div>
                        {!this.state.currentAlbumId &&
                          <div className="spinner">
                            <div className="double-bounce1"></div>
                            <div className="double-bounce2"></div>
                          </div>
                        }
                        </div>

                      </div>
                    ))}
                  </tbody>
                </table>
            </div>
          }
          {!this.state.updateContent &&
          <div className="spinner">
            <div className="double-bounce1"></div>
            <div className="double-bounce2"></div>
          </div>
          }
        </div>
      )
    }
  }

export default MediaLibrary;
