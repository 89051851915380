import React, { Component } from 'react';
import axios from 'axios';

class DeleteProject extends Component {

  constructor(props) {
    super(props);
    this.state = {};
  };

  deleteProject = (event) => {
    event.preventDefault();
    let currentProjectId = this.props.currentProject.id;
    let targetElement = document.getElementById('projectRow' + this.props.currentProject.id);

    document.getElementById('deleteProjectButton' + this.props.currentProject.id).setAttribute('disabled', 'true');
    document.getElementById('deleteProjectButton' + this.props.currentProject.id).innerHTML = 'Deleting...';

    axios.delete('/api/project/' + currentProjectId)
    .then((result) => {
      if (result.status === 200) {

        this.onDeleteProject(currentProjectId)
        try {
          targetElement.className = 'animated fadeOut';
          setTimeout(function(){targetElement.style.display = 'none';}, 500);
        } catch (error) {};

      };
    }, (error) => {
      try {
        document.getElementById('deleteProjectButton' + this.props.currentProject.id).className = 'btn btn-danger';
        document.getElementById('deleteProjectButton' + this.props.currentProject.id).innerHTML = 'Failed';
        document.getElementById('deleteProjectButton' + this.props.currentProject.id).removeAttribute('disabled');
      } catch (error) {};
    });
  };

  onDeleteProject = (deletedProjectId) => {
    this.props.onDeleteProject(deletedProjectId)
  }

  render() {

    return (
      <span>

        <span>
          <i className="fas fa-trash" style={{cursor: "pointer"}} data-toggle="modal" data-target={"#deleteProjectModal" + this.props.currentProject.id}/>
        </span>

        <div className="modal text-dark fade" id={"deleteProjectModal" + this.props.currentProject.id} style={{backgroundColor:'rgba(0, 0, 0, 0.5)'}} data-backdrop="false" tabIndex="-1" role="dialog" aria-labelledby="deleteProjectModalTitle" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title" id="deleteProjectModalTitle">{"WARNING"}</h4>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
              <form id="deleteProjectForm" onSubmit={this.deleteProject}>
                <h5>Are you sure you want to delete project {this.props.currentProject.name}?</h5>
                <div className="modal-footer">
                  <button id={'deleteProjectButton' + this.props.currentProject.id} type="submit" className="btn btn-danger">DELETE</button>
                  <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancel</button>
                </div>
              </form>
              </div>
            </div>
          </div>
        </div>
      </span>
    )
  }
}

export default DeleteProject;
