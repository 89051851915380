import React, {Component, Fragment} from 'react'
import YouTube from 'react-youtube';
import axios from 'axios'
import io from 'socket.io-client';

import CommentsTable from './CommentsTable';

let mediaArray = []
let mediaControlArray = {}

const UTIL = require('../UtilityFunction/UtilityFunction');

class MultiCamViewer extends Component {

    constructor(props) {
        super(props)
        this.state = {
            mountCommentTable: false,
            currentProject: undefined,
            currentMedia: undefined,
            currentHighlight: undefined,
            warningBar: undefined
        }
    }

    componentDidMount () {
      try {
        mediaArray = this.props.location.state.currentProject.mediaArray
        this.setState({currentProject: this.props.location.state.currentProject})
        this.setState({currentMedia: mediaArray[0]}, function() {
          this.setState({currentHighlight: this.state.currentMedia.id})
        })
        this.setState({mountCommentTable: true})
        this.forceUpdate()
      }
      catch (error) {
        console.log(error)
        window.location.assign('/')
      }
    }

    onChange = (event) => {

        this.setState({controlCommand: 'shuttleStop'});
        document.getElementById('commentButton').className='btn btn-outline-light';
        document.getElementById('commentButton').innerHTML='Comment';
    
        if (event.target.value.slice(0,8) === 'filter: ') {
          this.setState({searchString: event.target.value.slice(8)});
          event.target.className='form-control bg-dark text-white'
          document.getElementById('filterButton').className='btn btn-warning';
          return;
        };
    
        if (event.target.value.slice(0,8) === 'global: ') {
          event.target.className='form-control bg-dark text-white';
          document.getElementById('globalCommentButton').className='btn btn-warning';
          return;
        };
    
        if (this.state.searchString === undefined) {
          return;
        } else {
          this.setState({searchString: undefined});
          event.target.className='form-control';
          document.getElementById('filterButton').className='btn btn-outline-light';
          document.getElementById('globalCommentButton').className='btn btn-outline-light';
        };
      };
    
      onSubmit = (event) => {
    
        event.preventDefault();
    
        let commentField = document.getElementById('commentField');
        let commentBody = undefined;
        let isGlobal = false;
    
        // resume playback if there is nothing to submit
        if (commentField.value === '' || commentField.value.slice(0,8) === 'filter: ' || (commentField.value.slice(0,8) === 'global: ' && commentField.value.slice(8) === '')) {
          this.resumePlayback();
        };
    
        if (commentField.value.slice(0,8) === 'global: ') {
          commentBody = commentField.value.slice(8);
          isGlobal = true;
        } else {
          commentBody = commentField.value;
        };
    
        // data preparation
        if (!UTIL.stringCheck(commentBody)) {return};
    
        let commentToSend;
    
        if (this.state.currentMedia.type !== 'Album') {
          commentToSend = {
            media: this.state.currentMedia.id,
            body: commentBody,
            seconds: mediaControlArray[this.state.currentHighlight].getCurrentTime(),
            isGlobal: isGlobal
          };
        };
    
        // call api to post comment
        axios.post('/api/comment/media/' + commentToSend.media, commentToSend)
        .then((response) => {
          if (response.status === 200) {  
            document.getElementById('commentField').value = '';
            document.getElementById('commentButton').className = 'animated bounceIn btn btn-success';
            document.getElementById('commentButton').innerHTML = 'Commented!';
            document.getElementById('commentField').className = 'form-control';
            setTimeout(function() {
              document.getElementById('commentButton').className = 'btn btn-outline-light';
              document.getElementById('commentButton').innerHTML = 'Comment';
            }, 1500);
          };
        }, (error) => {
          console.log(error);
        });
      };

      highlightVideo(event) {
          event.preventDefault()
          window.clearInterval(this.state.activeInterval)
          this.setState({mountCommentTable: false})
          mediaArray.map((each) => {
              if (each.id === event.target.id) {
                  this.setState({currentMedia: each}, (function(){
                    // Establish connection to Socket.io server
                    this.socket = io({transports: ['websocket']});
                    this.socket.emit('subscribeMedia', [each, this.props.currentUser.userName]);
                    this.setState({currentHighlight: this.state.currentMedia.id})
                    this.forceUpdate()
                }))
              }
              return each
          })
          setTimeout(function(){
              this.setState({mountCommentTable: true})
            }.bind(this), 1000)
          }

        pauseAll(event) {
          mediaArray.map((each) => {
            mediaControlArray[each.id].pauseVideo()
            return each
          })
        }

        playAll(event) {
          mediaArray.map((each) => {
            mediaControlArray[each.id].playVideo()
            return each
          })
        }

        muteAll(event) {
          mediaArray.map((each) => {
            mediaControlArray[each.id].mute()
            return each
          })
        }

        syncPlayback(event) {
          this.pauseAll()
          this.muteAll()
          this.setState({warningBar: undefined})
          let controlID = this.state.currentHighlight
          let syncTime = mediaControlArray[controlID].getCurrentTime()
          mediaArray.map((each) => {
            if (each.id !== controlID) {
              mediaControlArray[each.id].mute()
              if (mediaControlArray[each.id].getDuration() < syncTime) {
                this.setState({warningBar: "unable to sync: insufficient media duration"})
              } else {
                mediaControlArray[each.id].seekTo(syncTime)
              }
            } else {
              mediaControlArray[each.id].unMute()
            }
            return each
          })
          this.playAll()
        }

        onYouTubePlayerReady = (event) => {
          // store a YT player reference for each video
          mediaArray.map((each) => {
            if (each.sourceMedia === event.target.getVideoData().video_id) {
              mediaControlArray[each.id] = event.target
            }
            return each
          })
        };

    render() {
        
        let YOUTUBE_OPTIONS = {
            height: 740,
            width:'100%',
            playerVars: {
                frameBorder: 0,
                autoplay: 0,
                rel: 0,
                showinfo: 0,
                controls: 1,
                modestbranding: 1,
                loop: 1,
                playsinline: 1
            }
        };
          
        return(
            <Fragment>
            
            {this.state.currentProject &&
            <h3 className="text-white">{'Project ' + this.state.currentProject.name + ' [Multi-Cam Mode]'}</h3>
            }

            {this.state.warningBar ?
              <div className="animated fadeIn progress">
              <div className="progress-bar progress-bar-striped bg-warning" role="progressbar" aria-valuenow="100"
                  aria-valuemin="0" aria-valuemax="100" style={{"width":"100%"}}>
                  {this.state.warningBar}
                  </div>
              </div>
              :
              <div className="animated fadeIn progress">
              <div className="progress-bar bg-secondary" role="progressbar" aria-valuenow="100"
                  aria-valuemin="0" aria-valuemax="100" style={{"width":"100%"}}>
                  </div>
              </div>
            }

            <div className="container-fluid" id="MultiCamMediaDisplayWindow" style={{position: 'relative', height: 800, background:'#000000'}}>
                <div className="row text-white">
                    {mediaArray.map((each) => (
                        <div className="col-sm" id={each.id} onClick={this.highlightVideo.bind(this)} style={{"border": each.id === this.state.currentHighlight ? "20px solid red" : "20px solid grey"}}>
                        <YouTube videoId={each.sourceMedia} opts={YOUTUBE_OPTIONS} onReady={this.onYouTubePlayerReady} currentMedia={each}/>
                        <div>{each.title}</div>
                        </div>
                    ))
                    }
                </div>                
            </div>

            <hr/>
            <div className="row text-white">
            <div className="col-sm" >
            <button type="button" className="btn btn-success" onClick={this.syncPlayback.bind(this)}>SYNC & PLAY</button>
            </div>
            <div className="col-sm" onClick={this.pauseAll}>
            <button type="button" className="btn btn-danger" onClick={this.syncPlayback.bind(this)}>PAUSE ALL</button>
            </div>
            </div>
            <hr/>

            <form onSubmit={this.onSubmit}>
              <div className="container-fluid">
                <div className="row d-flex justify-content-center">
                  <div className="col">
                    <span>&nbsp;&nbsp;&nbsp;</span>
                    <span>
                      <input className="form-control" id="commentField" style={{display: "inline", width: "540px"}} type="text" name="content" placeholder="Comment goes here... Press Enter to resume playback..." spellCheck={true} autoComplete="off" onBlur={this.handleOnBlur} onChange={this.onChange} autoFocus={true}/>
                    </span>
                    <span>&nbsp;&nbsp;&nbsp;</span>
                    <span>
                      <button className="btn btn-outline-light" id="commentButton" style={{width: 120}}type="submit" name="comment"><span>Comment</span></button>
                    </span>
                    <span>&nbsp;&nbsp;&nbsp;</span>
                  </div>
                </div>
              </div>
              <br/>
            </form>

            <div>
                {this.state.mountCommentTable &&
                    <CommentsTable searchString={""} currentUser={this.props.currentUser} currentMedia={this.state.currentMedia}/>
                }
            </div>

            </Fragment>
        )
    }
    
}

export default MultiCamViewer
