import React, { Component } from 'react';
import axios from 'axios';

const UTIL = require('../UtilityFunction/UtilityFunction');

class UpdatePassword extends Component {

  constructor(props) {
    super(props);
    this.state = {
      currentPassword: '',
      newPassword: '',
      confirmPassword: ''
    };
  };

  onChange = (event) => {
    event.preventDefault();
    this.setState({[event.target.name]: event.target.value});
    document.getElementById('currentPasswordDidFailValidation').innerHTML = '';
    document.getElementById('newPasswordDidFailValidation').innerHTML = '';
    document.getElementById('confirmPasswordDidFailValidation').innerHTML = '';
    document.getElementById('updatePasswordButton').className = 'btn btn-primary';
    document.getElementById('updatePasswordButton').innerHTML = 'Update';
  }

  updatePassword = (event) => {
    event.preventDefault();

    if (this.state.currentPassword === '') {
      document.getElementById('currentPasswordDidFailValidation').innerHTML = 'Please enter your current password';
      return;
    };

    if (this.state.newPassword === '') {
      document.getElementById('newPasswordDidFailValidation').innerHTML = 'Please enter your new password';
      return;
    };

    if (this.state.newPassword !== this.state.confirmPassword) {
      document.getElementById('confirmPasswordDidFailValidation').innerHTML = 'Password does not match the confirm password';
      return;
    };

    if (!UTIL.pwdComplexityCheck(this.state.newPassword)) {
      document.getElementById('confirmPasswordDidFailValidation').innerHTML = 'At least 8 characters long and 1 letter from [a-z], [A-z], [0-9]';
      return;
    }

    document.getElementById('updatePasswordButton').setAttribute('disabled', 'true');
    document.getElementById('updatePasswordButton').innerHTML = 'Updating...'
    let updatePassword = {};
    updatePassword.newPassword = this.state.newPassword;
    updatePassword.currentPassword = this.state.currentPassword;
    axios.patch('/api/user/updatePassword', updatePassword)
    .then((result) => {
      if (result.status === 200) {
        window.location.reload();
      }
    }, (error) => {
      document.getElementById('updatePasswordButton').innerHTML = 'Failed';
      document.getElementById('updatePasswordButton').className = 'btn btn-danger';
      document.getElementById('updatePasswordButton').removeAttribute('disabled');
      if (error.response.data.message.toLowerCase().includes('current')) {
        document.getElementById('currentPasswordDidFailValidation').innerHTML = error.response.data.message;
      };
    })
  };

  render() {

    return (
      <span>

      <button type="button" className="btn btn-warning" data-toggle="modal" data-target="#updatePasswordModal">
        Update
      </button>

        <div className="modal text-dark fade" id="updatePasswordModal" style={{backgroundColor:'rgba(0, 0, 0, 0.8)'}} data-backdrop="false" tabIndex="-1" role="dialog" aria-labelledby="updatePasswordModalTitle" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title" id="updatePasswordModalTitle">Update password</h4>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
              <form id="updatePasswordForm" onSubmit={this.updatePassword}>
                <div className="form-group">
                  <span className="text-danger" id="currentPasswordDidFailValidation"></span>
                  <input className="form-control" id="currentPasswordField" type="password" name="currentPassword" placeholder="Current password" value={this.state.currentPassword} onChange={this.onChange}/>
                </div>
                <div className="form-group">
                  <span className="text-danger" id="newPasswordDidFailValidation"></span>
                  <input className="form-control" id="newPasswordField" type="password" name="newPassword" placeholder="New password" value={this.state.newPassword} onChange={this.onChange}/>
                </div>
                <div className="form-group">
                  <span className="text-danger" id="confirmPasswordDidFailValidation"></span>
                  <input className="form-control" id="confirmPasswordField" type="password" name="confirmPassword" placeholder="Confirm password" value={this.state.confirmPassword} onChange={this.onChange}/>
                </div>
                <div>
                <hr/>
                <p>Forgot your current password? <a href="/password-reset">Reset it here</a></p>
                </div>
                <div className="modal-footer">
                  <button id='updatePasswordButton' type="submit" className="btn btn-primary">Update</button>
                  <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancel</button>
                </div>
              </form>
              </div>
            </div>
          </div>
        </div>
      </span>
    )
  }
}

export default UpdatePassword;
