import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';

import RemoveCollaborator from './RemoveCollaborator';

class Collaboration extends Component {

  constructor(props) {
    super(props);
    this.state = {
      collaboratorEmail: undefined
    };
  };

  onChange = (event) => {
    event.preventDefault();
    this.setState({ [event.target.name]: event.target.value });
    document.getElementById('addCollaboratorButton').innerHTML = 'Add';
    document.getElementById('addCollaboratorButton').className = 'btn btn-primary';
    document.getElementById('invitationDidFailValidationWarning').innerHTML = '';
  };

  onSubmit = (event) => {
    event.preventDefault();

    if (document.getElementById('modalDialogInput').value === '') {
      return;
    }

    if (document.getElementById('modalDialogInput').value === this.props.currentUser.email) {
      document.getElementById('invitationDidFailValidationWarning').innerHTML = 'You can not invite your self to collaborate :)'
      return;
    }

    document.getElementById('addCollaboratorButton').setAttribute('disabled', 'true');
    document.getElementById('addCollaboratorButton').innerHTML = 'Adding...';

    axios.post('/api/invitation/', {project: this.props.currentProject.id, invitee: this.state.collaboratorEmail})
    .then((result) => {
      if (result.status === 200) {
        document.getElementById('modalDialogInput').value = '';
        document.getElementById('dismissAddCollaboratorModal').click();
      };
    }, (error) => {
      document.getElementById('invitationDidFailValidationWarning').innerHTML = error.response.data.message;
      document.getElementById('addCollaboratorButton').removeAttribute('disabled');
      document.getElementById('addCollaboratorButton').innerHTML = 'Failed';
      document.getElementById('addCollaboratorButton').className = 'btn btn-danger';
    });
  };

  launchMultiCamMode() {
    this.setState({launchMultiCamMode:true})
  }

  render() {

    let { collaboratorEmail } = this.state;

    return (
      <span>
        <div className='container-fluid'>
          <div className="row">
            
            {this.props.multiCamCompatible &&
            <button type="button" className="col btn btn-primary" style={{marginRight:10}} onClick={this.launchMultiCamMode.bind(this)}>Launch Multi-Cam Mode</button>
            }
            {this.state.launchMultiCamMode &&
             <Redirect to={{
              pathname: '/multicam',
              state: { currentProject: this.props.currentProject }
              }}/>
            }

            {this.props.currentUser.id === this.props.currentProject.owner &&
                <button type="button" className="col btn btn-primary" style={{marginRight:5}} data-toggle="modal" data-target="#addCollaboratorModal">
                  Add collaborator
                </button>
            }

              <button type="button" className="col btn btn-primary" style={{marginLeft:5}} data-toggle="modal" data-target="#showCollaboratorsModal">
                Show collaborators
              </button>
          </div>
        </div>

        <div className="modal text-dark fade" id="addCollaboratorModal" style={{backgroundColor:'rgba(0, 0, 0, 0.5)'}} data-backdrop="false" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="addCollaboratorModalTitle">Add collaborator to project: {this.props.currentProject.name}</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
              <form onSubmit={this.onSubmit}>
                <small className="text-danger" id="invitationDidFailValidationWarning"></small>
                <div className="form-group">
                  <input className="form-control" id="modalDialogInput" type="email" name="collaboratorEmail" placeholder="Collaborator email" value={collaboratorEmail} onChange={this.onChange}/>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" id="dismissAddCollaboratorModal" data-dismiss="modal">Cancel</button>
                  <button id='addCollaboratorButton' type="submit" className="btn btn-primary">Add</button>
                </div>
              </form>
              </div>
            </div>
          </div>
        </div>

        <div className="modal text-dark fade" id="showCollaboratorsModal" style={{backgroundColor:'rgba(0, 0, 0, 0.5)'}} data-backdrop="false" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="showCollaboratorsModalTitle">Collaborators</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
              {this.props.currentProject.collaborators.map((each) => (
                <div key={each.id} className="row">
                  <div className="col">{each.userName}</div>
                  {(this.props.currentUser.id === this.props.currentProject.owner) &&
                  <div className="col">
                    <RemoveCollaborator currentCollaborator={each} currentProject={this.props.currentProject}/>
                  </div>
                  }
                </div>
              ))}
              {this.props.currentProject.collaborators.length === 0 &&
                <p>No collaborator currently</p>
              }
              </div>
            </div>
          </div>
        </div>
      </span>
    )
  }
}

export default Collaboration;
